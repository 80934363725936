
import React, { Component } from 'react'

import { StyleSheet, Dimensions, View, Image, Platform, Text } from 'react-native'

import { ActivityIndicator, Colors } from 'react-native-paper';

import 'react-native-gesture-handler';

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase'

export class Neworg extends Component {

  constructor(props){
      super(props);

      this.state = {
        currentUser: props.route.params.currentUser,

        orgName: '',
        orgNameError: '',

        error: '',

        loading: false,

      }

  }

  validOrgName(name){ //eventuellt lägg till en loader som rullar medans validating
    if(name){
    }else{
        this.setState({ orgNameError: "College name can't be empty.", error: '' })
        return null
    }
    if(name.length > 50){
      this.setState({ orgNameError: "Name can't be longer than 50 characthers.", error: '' })
      return null
    }
    if (name.match(/^[ A-Ö´`0-9'-., ]+$/i) === null) { 
        this.setState({ orgNameError: "The name is badly formatted.", error: '' })
        return null
    }else{
    }
    this.checkId(name)
  }

  checkId(name){
    this.setState({ loading: true })
    firebase.firestore().collection("orgs").where(firebase.firestore.FieldPath.documentId(), "==", name.toUpperCase()).get({source: 'server'})
      .then(snapshot => {
        console.log(snapshot)
          if(snapshot.empty){
            this.create(name)
          }else{
            this.setState({ orgNameError: "This college already in our system.", error: '' })
            this.setState({ loading: false })
          }
      })
      .catch((err) => {
        this.setState({ orgNameError: "", error: 'Oops... Something went wrong!' })
        this.setState({ loading: false })
      });
  }

  create(name){

    const currentUser = this.state.currentUser;
    const id = name.toUpperCase()

    firebase.firestore().collection('orgs')
        .doc(id)
        .set({
          name,
          creation: firebase.firestore.FieldValue.serverTimestamp(),
          admin: currentUser.uid,
          activationcode: '',
        })
    
    firebase.firestore().collection('users').doc(currentUser.uid)
      .collection('orgs')
      .doc(id)
      .set({})

    this.props.navigation.popToTop();
  }

  componentDidMount(){ //whenever component mounts and will render next
  }

  render() {

    const orgNameError = this.state.orgNameError;

    const error = this.state.error;

    const loading = this.state.loading;

    return (

      <Background>

        <BackButton goBack={this.props.navigation.goBack}/>
        
        <Header>Add new college</Header>

        {error
          ?<Text style={styles.error}>{error}</Text>
          : null
        }

        <TextInput
                    label="College name"
                    returnKeyType="next"
                    value={this.state.orgName}
                    onChangeText={(orgName) => this.setState({ orgName })}
                    error={!!orgNameError}
                    errorText={orgNameError}
                />

        {loading
        ? <Button mode="outlined" style={styles.disabled}> 
          Loading...
            <ActivityIndicator animating={loading} color={'#28D158'} /> 
          </Button> 
        : <Button mode="outlined" onPress={() => this.validOrgName(this.state.orgName)}> 
          Add {this.state.orgName}
          </Button>
        }

        <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
          <Text style={styles.text}>
            Flipfire2 - Developed in Switzerland 2022©
          </Text>
        </View>

      </Background>

    )
  }
}

export default Neworg

const styles = StyleSheet.create({
  error: {
      fontSize: 13,
      color: theme.colors.error,
      paddingTop: 8,
  },
  disabled: {
    opacity: 0.25,
  },
  text: {
    color: theme.colors.text,
    fontFamily: 'Helvetica Neue',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 21,
},
})