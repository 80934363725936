import React from 'react'
import { StyleSheet, Dimensions } from 'react-native'
import { Button as PaperButton } from 'react-native-paper'
import { theme } from '../../core/theme'

import { isMobileAll as isMobile } from '../../deviceDetect';

export default function Button({ mode, style, ...props }) {
  return (
    <PaperButton
      style={[
        styles.button,
        mode === 'outlined' && {
          backgroundColor: theme.colors.surface,
          borderColor: theme.colors.text,
        },
        style,
      ]}
      labelStyle={ (mode != 'outlined' ? styles.text : styles.text2) }
      mode={mode}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    maxWidth: Dimensions.get('window').width / 2,
    width: (isMobile ? '100%' : '20%'),
    marginVertical: 10,
    paddingVertical: 2,
    marginHorizontal: 10,
  },
  text: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 26,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    color: theme.colors.surface,
  },
  text2: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 26,
    color: theme.colors.secondary,
  },
})
