import React, { Component } from 'react'

import { isMobileAll as isMobile } from '../../deviceDetect';

import { format } from 'date-fns'

import { StyleSheet, Dimensions, View, Image, Platform, FlatList, Text, TouchableOpacity, Touchable, ImageBackground } from 'react-native'

import { Dialog, Portal, ActivityIndicator, Colors, Banner } from 'react-native-paper';

import 'react-native-gesture-handler';

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Paragraph2 from '../elements/Paragraph2'
import Button from '../elements/Button'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase'
import { style } from 'dom-helpers';

import NetInfo from '@react-native-community/netinfo';

export class Dashboard extends Component { 

  constructor(props){
      super(props);

      this.state = {
        currentUser: props.route.params.params,
        orgs: null,

        loading: true,

        showDelete: false,
        pendingDel: null,

        showEnterCode: false,
        code: '',
        codeError: '',

        showNetError: false,

      }

  }

  /*async confirmCode(){
    const code = this.state.code
    console.log(code, 'LOGGED CODE')
    if(code){
      await firebase.firestore().collection('orgs').get()
        .then(snapshot => {
          if(!snapshot.empty){
            const newProm = new Promise((resolve) => {
              snapshot.docs.forEach(async (doc, index, array) => {
                console.log(doc.data().activationcode, 'ACT CODE from all', code, doc.id)
                await firebase.firestore().collection('orgs').where('activationcode', '==', code).get()
                .then(snapshot => { 
                  console.log(snapshot, 'THIS IS ALL RETURNED FROM COMPARSION...')
                  if(!snapshot.empty){
                    firebase.firestore().collection('users').doc(this.state.currentUser.uid).collection('orgs')
                    .doc(doc.id)
                    .set({})
                    this.setState({ showEnterCode: false }) //la till nyligen
                    console.log(doc.data().activationcode, code, 'MATCH', snapshot)
                    //resolve()
                }else{
                    console.log('finns ej')
                    this.setState({ codeError: "This code doesn't match any organization." })
                    //resolve()
                }
                });
                if (index === array.length - 1) resolve();
              })
            });
            newProm.then(() => {
              console.log('klaaaaarrrrr')
            });
          }else{
            this.setState({ codeError: "This code doesn't match any organization." })
          }
      })
    }
  }*/

  async confirmCode(){
    const code = this.state.code
    console.log(code, 'LOGGED CODE')
    if(code){
      const newProm = new Promise(async (resolve) => {
      await firebase.firestore().collection('orgs').where('activationcode', '==', code).get()  //ev. gör så att enbart unika koder kan skapas annas reject
        .then(snapshot => { 
          console.log(snapshot, 'THIS IS ALL RETURNED FROM COMPARSION...')
          if(!snapshot.empty){
            console.log(snapshot, 'SNAP')
            snapshot.forEach((doc) => {
              firebase.firestore().collection('users').doc(this.state.currentUser.uid).collection('orgs')
              .doc(doc.id)
              .set({})
              this.setState({ showEnterCode: false, code: '', codeError: ''})
              resolve();
            });
          }else{
            console.log('finns ej')
            this.setState({ codeError: "This code doesn't match any organization." })
            resolve();
          }
        });
        //resolve();
        });
        newProm.then(() => {
        //console.log('klaaaaarrrrr')
        });
    }
  }

  openDelete(item){
    this.setState({ showDelete: true, pendingDel: item })
  }

  async delete(){
    this.setState({ showDelete: false, loading: true, openDelete: false })
    const pendingDel = this.state.pendingDel
    const currentUser = this.state.currentUser
    if(pendingDel.admin === currentUser.uid){

      await firebase.firestore().collection('orgs').doc(pendingDel.id).collection('screens').get()
        .then(snapshot => {
          snapshot.docs.forEach(doc => {
            var id = doc.id
            firebase.firestore().collection('orgs').doc(pendingDel.id).collection('screens').doc(doc.id).collection('cards').get()
            .then(snapshot => {
              snapshot.docs.forEach(doc => {
                firebase.firestore().collection('orgs').doc(pendingDel.id).collection('screens').doc(id).collection('cards').doc(doc.id)
                .delete();
                console.log('deletedddd')
              })
            })
            firebase.firestore().collection('orgs').doc(pendingDel.id).collection('screens').doc(id).delete();
          })
        })
        await firebase.firestore().collection('orgs').doc(pendingDel.id).delete();

      await firebase.firestore().collection('users').get()
        .then(snapshot => {
          if(!snapshot.empty){
            const newProm = new Promise((resolve) => {
              snapshot.docs.forEach(async (doc, index, array) => {
                await firebase.firestore().collection('users').doc(doc.id).collection('orgs').where(firebase.firestore.FieldPath.documentId(), "==", pendingDel.id).get()
                .then(snapshot => {
                  firebase.firestore().collection('users').doc(doc.id).collection('orgs')
                  .doc(pendingDel.id)
                  .delete();
                });
                if (index === array.length - 1) resolve();
              })
            });
            newProm.then(() => {
            });
          }
        })
      
    }else{
      firebase.firestore().collection('users').doc(currentUser.uid).collection('orgs')
      .doc(pendingDel.id)
      .delete();
    }    
    this.setState({ pendingDel: null })
  }

  goToOrg(org){
    this.props.navigation.navigate("Homeorg", { org: org, currentUser: this.state.currentUser });
  }

  createNewOrg(){
    this.props.navigation.navigate("Neworg", { currentUser: this.state.currentUser });
    return
  }

  async loadOrgs(){

  const orgs = [];
      await firebase.firestore().collection('users').doc(this.state.currentUser.uid).collection('orgs').get()
        .then(snapshot => {
          if(!snapshot.empty){
            const newProm = new Promise((resolve) => {
              snapshot.docs.forEach(async (doc, index, array) => {
                await firebase.firestore().collection('orgs').where(firebase.firestore.FieldPath.documentId(), "==", doc.id).get()
                .then(snapshot => {
                  snapshot.docs.forEach((doc) => {
                    orgs.push({...doc.data(), id: doc.id});
                  })
                });
                if (index === array.length - 1) resolve();
              })
            });
            newProm.then(() => {
              this.setState({ orgs: orgs })
              this.setState({ loading: false })
            });
          }else{
            this.setState({ orgs: null })
            this.setState({ loading: false })
          }
        })
  }

  componentDidMount(){

    this.setState({ loading: true })

    this.unsubscribe = firebase.firestore().collection('users').doc(this.state.currentUser.uid).collection('orgs').onSnapshot(this.onCollectionUpdate) 

    this.unsubNet = NetInfo.addEventListener(state => {
      this.setState({ showNetError: !state.isConnected })
    });
  }

  componentWillUnmount() {
    this.unsubscribe()
    this.unsubNet();
  }

  onCollectionUpdate = (querySnapshot) => {
    this.loadOrgs()
  }

  render() {

    const currentUser = this.state.currentUser

    const orgs = this.state.orgs

    const loading = this.state.loading

    const showDelete = this.state.showDelete

    const showEnterCode = this.state.showEnterCode

    const codeError = this.state.codeError

    const showNetError = this.state.showNetError
 
    return (

      <Background>

          <Portal>
              <Dialog style={{backgroundColor: theme.colors.surface, width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={showNetError} onDismiss={() => this.setState({ showNetError: false })}>
              <Dialog.Content>
                  <Text style={{fontFamily: 'Helvetica Neue', fontSize: 40, fontWeight: 'bold', color: theme.colors.error}}>No internet connection found! Changes may be lost.</Text>
                  <Button mode="contained" style={{width: (isMobile ? '100%' : '40%'), alignSelf: 'center'}} onPress={() => this.setState({ showNetError: false })}>
                      Noted
                  </Button>
              </Dialog.Content>
              </Dialog>
          </Portal>

          {this.state.pendingDel
          ? <Portal>
              <Dialog visible={showDelete} style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} onDismiss={() => this.setState({ showDelete: false })}>
                  
                  <Dialog.Content>
                      <Paragraph>Are you sure you want to {this.state.pendingDel.admin === currentUser.uid ? 'delete' : 'remove yourself from' } {this.state.pendingDel.name}? {'\n'}
                      {this.state.pendingDel.admin === currentUser.uid 
                      ? <Paragraph> No one will be able to access {this.state.pendingDel.name} any more! </Paragraph>
                      : null
                      }
                      </Paragraph>
                  </Dialog.Content>

                  <Dialog.Actions style={{justifyContent: 'center'}}>

                      <Button style={{width: (isMobile ? '30%' : '40%')}} mode="outlined" onPress={() => this.delete()}>Yes</Button>
                      <Button style={{width: (isMobile ? '30%' : '40%')}} mode="contained" onPress={() => this.setState({ showDelete: false })}>Cancel</Button>
              
                  </Dialog.Actions>

          
              </Dialog>

          </Portal>
          :null
          }

          <Portal>

              <Dialog style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={showEnterCode} onDismiss={() => this.setState({ showEnterCode: false, code: '', codeError: ''})}>
            
              <ImageBackground
                source={require('../../srcassets/doubledot.png')}
                resizeMode="repeat"
              
                style={{width: '100%', height: '100%'}}
              >

                <Dialog.Content>

                  <View style={{width: (isMobile ? '100%' : '130%'), alignSelf: 'center', paddingTop: 15}}>
                    <TextInput
                    label="Access code"
                    returnKeyType="next"
                    value={this.state.code}
                    onChangeText={(code) => this.setState({ code })}
                    error={!!codeError}
                    errorText={codeError} 
                  />
                  </View>

                  <Button mode="outlined" style={{width: (isMobile ? '100%' : '40%'), alignSelf: 'center'}} onPress={() => this.confirmCode()}>
                    Activate
                  </Button>

                </Dialog.Content>

              </ImageBackground>
              </Dialog>
          </Portal>

        <Header>Welcome {currentUser.displayName}!</Header>

        {loading
        ? <ActivityIndicator animating={loading} color={theme.colors.loading} /> 
        : [
          (!orgs
            ? <View style={{backgroundColor: theme.colors.surface, marginTop: 20, width: (isMobile ? '90%' : '60%'), height: '40%', minHeight: 450, justifyContent: 'center', borderRadius: 7, borderColor: theme.colors.text, borderWidth: 2}}>
            
              <Paragraph2>
                Looks like you don't have a college yet.{'\n'}

                {'\n'}{'\n'}Create one{'\n'}

                {'\n'}-OR-{'\n'}

                {'\n'}Ask your college for an access code.{'\n'}
              </Paragraph2>

              <Image style={{height: (isMobile ? 170 : 200), width: '90%', alignSelf: 'center', marginTop: 15}}source={{uri: "https://media.giphy.com/media/l2JhORT5IFnj6ioko/giphy.gif"}} />

              </View>

            : <View style={{backgroundColor: theme.colors.surface, marginTop: 20, width: (isMobile ? '90%' : '60%'), height: '40%', minHeight: 450, justifyContent: 'center', borderRadius: 7, borderColor: theme.colors.text, borderWidth: 2}}>
                
                <Paragraph2 bold={true}>My colleges:</Paragraph2>
              
                  <FlatList
                    data={orgs}
                    keyExtractor={item => item.id}
                    renderItem={({ item }) => (
                      <TouchableOpacity style={[styles.row, {backgroundColor: theme.colors.text, borderRadius: 7, marginVertical: 10, marginHorizontal: 15, minHeight: 60}]} onPress={() => this.goToOrg(item)}>
                          
                            <View style={{flex: 1, alignSelf: 'center'}}>
                              <Paragraph>{item.name}</Paragraph> 
                            </View>

                            <TouchableOpacity style={{alignSelf: 'center', marginRight: 10}} onPress={() => this.openDelete(item)}>
                              <Image
                              style={{height: 30, width: 30}}
                              source={require('../../srcassets/trash.png')}
                              />
                            </TouchableOpacity>
                          </TouchableOpacity>

                    )}

                  />

                </View>

            )]
            }

        <View style={{flexDirection: (isMobile ? 'column' : 'row'), alignSelf: 'center', justifyContent: 'center', minWidth: '100%', marginBottom: 70}}>
      
          <Button mode="contained" style={{ marginVertical: (isMobile ? 10 : 30), marginHorizontal: 10, alignSelf: 'center', width: (isMobile ? '100%' : '20%')}} onPress={() => this.createNewOrg()}>
            Add new college
          </Button>

          <Button mode="contained" style={{ marginVertical: (isMobile ? null : 30), marginHorizontal: 10, alignSelf: 'center', width: (isMobile ? '100%' : '20%')}} onPress={() => this.setState({ showEnterCode: true })}>
            Enter access code
          </Button>
          
        </View>

        <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
          <Text style={styles.text}>
            Flipfire2 - Developed in Switzerland 2022©
          </Text>
        </View>

      </Background>

    )
  }
}

export default Dashboard

const styles = StyleSheet.create({
  row: {
      flexDirection: 'row',
      marginTop: 4,
  },
  text: {
      color: theme.colors.text,
      fontFamily: 'Helvetica Neue',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 21,
  },
})


