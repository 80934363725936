
import React, { Component } from 'react'; 

import { isMobileAll as isMobile } from '../../deviceDetect';

import { StyleSheet, View, Image, Platform, Text, TouchableOpacity, Dimensions } from 'react-native';

import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';

import { WebView } from 'react-native-webview';

import 'react-native-gesture-handler';

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Paragraph2 from '../elements/Paragraph2'
import Button from '../elements/Button'

import { theme } from '../../core/theme'

import Dashboard from './Dashboard';
import Settings from './Settings';
import About from './About';
import Suggest from './Suggest';

import firebase from 'firebase'

//import * as Analytics from 'expo-firebase-analytics';
/*if(isMobile){   //KOLLA OM DENNA ÄR AKTIV ELLER DEN ANDRA
    try {
      Analytics.logEvent('USER_ENTERED_APP', {
        time: new Date().toLocaleString()
      });
    } catch (error) {
      console.log(error)
    }
}*/

const Drawer = createDrawerNavigator();
export class Home extends Component {
    constructor(props){     //first to be called whenever a component is created
         super(props);
         this.state = {
            loggedIn: false,
            currentUser: undefined,
            videoLink: "https://www.youtube.com/embed/4Yehv_sqm0o",
         } 
    }

    login(){
        this.props.navigation.navigate("Login");
        return
    }
    
    signup(){
        this.props.navigation.navigate("Signup");
        return
    }

    flip(){
        this.props.navigation.navigate("Flip");
        return
    }

    dashIcon(){
        return(
            <Image
                style={{height: 20, width: 20}}
                source={require('../../srcassets/dash.png')}
            />
        )}

    kuggIcon(){
        return(
            <Image
                style={{height: 20, width: 20}}
                source={require('../../srcassets/kugg.png')}
            />
        )}

    aboutIcon(){
        return(
            <Image
                style={{height: 20, width: 20}}
                source={require('../../srcassets/about.png')}
            />
        )}

    suggestIcon(){
        return(
            <Image
                style={{height: 20, width: 20}}
                source={require('../../srcassets/suggest.png')}
            />
        )}

    componentDidMount(){
        this.Unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if(!user){
                this.setState({
                    loggedIn: false,
                    currentUser: undefined,
                })
            }else{
                this.setState({
                    loggedIn: true,
                    currentUser: user,
            })  
        }
        })
    }

    componentWillUnmount(){
        this.Unsubscribe()
    }

    render(){

        const loggedIn = this.state.loggedIn

        const currentUser = this.state.currentUser

        const videoLink = this.state.videoLink

        function CustomDrawerContent(props) {
            return(
              <DrawerContentScrollView {...props}>
                <DrawerItemList {...props} />
                    <View>
                        <Button mode="contained" style={{width: '60%', alignSelf: 'center'}} onPress={() => firebase.auth().signOut()}>
                            Logout
                        </Button>
                    </View>
              </DrawerContentScrollView> 
            );
        }

        function YoutubeEmbed() {
            return(
                <iframe
                    width={Dimensions.get('window').width*0.66}
                    height={Dimensions.get('window').width*0.33} 
                    src={videoLink}
                    frameBorder="0"
                    allowFullScreen
                />
            );
        }

        if(loggedIn){
            return(

                //<NavigationContainer independent={true}>
                    <Drawer.Navigator initialRouteName="Dashboard" drawerContent={(props) => <CustomDrawerContent {...props}/>} /*screenOptions={{headerStyle: {backgroundColor: '#ffffff'}, title: 'Flipfire2'}}*/>
                        <Drawer.Screen name="Dashboard" initialParams={{params: currentUser}} component={Dashboard} options={{drawerIcon:() => this.dashIcon(), headerShadowVisible: true, headerTitleAlign: Platform.OS === 'web' ? 'left' : 'center'}}/>
                        <Drawer.Screen name="Settings" initialParams={{params: currentUser}} component={Settings} options={{drawerIcon:() => this.kuggIcon(), headerShadowVisible: true, headerTitleAlign: Platform.OS === 'web' ? 'left' : 'center'}}/>
                        <Drawer.Screen name="About" initialParams={null} component={About} options={{drawerIcon:() => this.aboutIcon(), headerShadowVisible: true, headerTitleAlign: Platform.OS === 'web' ? 'left' : 'center'}}/>
                        <Drawer.Screen name="Suggest Feature" initialParams={null} component={Suggest} options={{drawerIcon:() => this.suggestIcon(), headerShadowVisible: true, headerTitleAlign: Platform.OS === 'web' ? 'left' : 'center'}}/>
                    </Drawer.Navigator>
                //</NavigationContainer>
            )
            
        }else{
            return(

                <Background>

                    {!isMobile
                    ?<View style={{flexDirection: 'row', justifyContent: 'flex-end', alignSelf: 'center', width: '100%', marginTop: 4}}>
                        <Button
                        style={{marginHorizontal: 20, width: '15%'}}
                        mode="contained"
                        onPress={() => this.login()} 
                        >
                            Login
                        </Button>
                        <Button
                            style={{width: '15%'}}
                            mode="outlined"
                            onPress={() => this.signup()}
                        >
                            Join us
                        </Button>
                    </View>
                    :null
                    }  

                    {!isMobile
                    ?<View style={styles.row}>
                        
                    <Logo/>
                
                    <Image
                    style={{height: 180, width: 570, marginHorizontal: 10}}
                    source={require('../../../assets/title.svg')}
                    />
              
                    </View> 
                    :<Image
                    style={{height: 120, width: 400, marginTop: 20, marginLeft: 35}}
                    source={require('../../../assets/title.png')}
                    />
                    } 

                    <Text style={{fontFamily: 'Helvetica Neue', textAlign: 'center', fontSize: (isMobile ? 15 : 30), fontWeight: 'bold', color: theme.colors.text}}>”Your students' safety is our priority.” {'\n'}<Text style={{fontStyle: 'italic', fontSize: (isMobile ? 10 : 15)}}> -The Flipfire2 Team</Text></Text> 

                    {isMobile
                    ?<WebView 
                    style={{width:Dimensions.get('window').width*0.9, height:Dimensions.get('window').width*0.3, marginTop: 30}}
                    source={{ uri: videoLink }}
                    />
                    :<Background>
                        <Text style={styles.customText2}>
                            Flipfire2 is the digital safety platform that allows you to know who is in and who is out in less than a second.
                        </Text>

                        <YoutubeEmbed/>
                    </Background>
                    }

                    {isMobile
                    ?<Background>
                        <Button
                        style={{width: '60%'}}
                        mode="contained"
                        onPress={() => this.login()}
                        >
                            Login
                        </Button>
                        <Button
                        style={{width: '60%'}}
                            mode="outlined"
                            onPress={() => this.signup()}
                        >
                            Signup
                        </Button>
                    </Background> 
                    :null 
                    } 

                    {isMobile
                    ?null
                    :<Background>

                        <View style={[styles.row, {alignItems: 'flex-start'}]}>

                            <Background style={{flexDirection: 'column', paddingHorizontal: 60}}>
                            
                            <Text style={[styles.customHeader, /*{textDecorationLine: 'underline'}*/]}>Step 1</Text>

                            <TouchableOpacity onPress={() => this.signup()}>
                                <Text style={[styles.customText, {textDecorationLine: 'underline'}]}> 
                                    Create your account 
                                </Text>
                            </TouchableOpacity>

                            </Background> 

                            <Background style={{flexDirection: 'column', paddingHorizontal: 60}}>

                            <Text style={[styles.customHeader, /*{textDecorationLine: 'underline'}*/]}>Step 2</Text>

                            <Text style={styles.customText}> 
                            Add your college and create a flip-screen to display the fire cards
                            </Text>

                            </Background>

                            <Background style={{flexDirection: 'column', paddingHorizontal: 60}}>

                                <Text style={[styles.customHeader, /*{textDecorationLine: 'underline'}*/]}>Step 3</Text>

                                <Text style={styles.customText}> 
                                Create an access code for your college to give access to staff
                                </Text>

                            </Background> 

                        </View>
 
                        <Background>
                            <View style={{flex: 1, alignItems: 'flex-start', justifyContent: 'space-between', paddingBottom: 200}}>
                                <Text style={{alignSelf: 'center', fontFamily: 'Helvetica Neue', textAlign: 'center', fontSize: (isMobile ? 10 : 35), fontWeight: 'bold', color: theme.colors.text}}>Almost done...</Text>
                               
                                <Text style={styles.customText}> 
                                    Login to your account on a device with a touch screen or{'\n'}
                                    connect an electronic key reader to any device.{'\n'}
                                    -We recommend SALTO.
                                </Text>

                                <Text style={{alignSelf: 'center', fontFamily: 'Helvetica Neue', textAlign: 'center', fontSize: (isMobile ? 10 : 35), fontWeight: 'bold', color: theme.colors.text, paddingTop:100}}>All done!</Text>
                                <Text style={{alignSelf: 'center', fontFamily: 'Helvetica Neue', textAlign: 'center', fontSize: (isMobile ? 10 : 25), color: theme.colors.text}}>You can now see who is in and who is out, using the app and the website.</Text>

                            </View>

                        </Background> 

                    </Background>
                    }
                    
                    <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
                    <Text style={styles.text}>
                        Flipfire2 - Developed in Switzerland 2022©
                    </Text>
                    </View>
        
                </Background>
            )
            }
    }
}

export default Home

const styles = StyleSheet.create({
    row: {
        alignSelf: 'center',
        flexDirection: 'row',
        marginTop: 4,
    },
    link: {
        fontWeight: 'bold',
        color: theme.colors.primary,
      },
    text: {
        color: theme.colors.text,
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 21,
    },
    customHeader: {
        fontFamily: 'Helvetica Neue',
        textAlign: 'center', 
        fontSize: 35, 
        fontWeight: 'bold', 
        color: theme.colors.text
    },
    customText: {
        alignSelf: 'center',
        fontFamily: 'Helvetica Neue', 
        textAlign: 'center', 
        fontSize: 20, 
        fontWeight: 'normal', 
        color: theme.colors.text,
        marginTop: 20,
    },
    customText2: {
        fontSize: 30,
        fontWeight: "bold",
        lineHeight: 32,
        textAlign: 'center',
        fontFamily: 'Helvetica Neue',
        color: theme.colors.primary,
        marginBottom: 30,
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
  })
  




/*{Click ”Display Mode” to show the flip-screen with the fire cards.}*/

//<Text style={{paddingTop:80, alignSelf: 'center', fontFamily: 'Helvetica Neue', textAlign: 'center', fontSize: (isMobile ? 10 : 25), color: theme.colors.text}}>Touch screen devices we recommend:</Text>

/*<View style={[styles.row, {alignItems: 'flex-start', paddingBottom: 40, paddingTop: 15}]}>

                                    <View style={{flexDirection: 'column', flex:1, alignSelf: 'flex-start'}}>
                                        <Image
                                        style={{height: 59, width: 80, alignSelf: 'center'}}
                                        source={require('../../srcassets/tablet.png')}
                                        />
                                        <Text style={styles.customText}>iPad &gt; 10.2 inch</Text> 
                                    </View>

                                    <View style={{flexDirection: 'column', flex:1, alignSelf: 'flex-start'}}>
                                        <Image
                                        style={{height: 66, width: 80, alignSelf: 'center'}}
                                        source={require('../../srcassets/tv.png')}
                                        />
                                        <Text style={styles.customText}>Smart TV</Text>
                                    </View>

                                    <View style={{flexDirection: 'column', flex:1, alignSelf: 'flex-start'}}>
                                        <Image
                                        style={{height: 73, width: 80, alignSelf: 'center'}}
                                        source={require('../../srcassets/computer.png')}
                                        />
                                        <Text style={styles.customText}>Stationary computer</Text>
                                    </View>

                                </View>*/
/*






<Header>Our Team</Header>

                        <View style={[styles.row, {alignItems: 'flex-start'}]}>

                            <Background style={{flexDirection: 'column', paddingHorizontal: 60}}>
                            
                            <Avatar.Image size={120} style={{marginBottom: 10}} source={require('../../../assets/icon.png')} />

                            <Text style={styles.text}>
                                Anas Abbassi {'\n'} <Text style={{fontStyle: 'italic'}}> Head of Development </Text> {'\n'}
                            </Text>

                            <TouchableOpacity onPress={() => Linking.openURL('mailto:anas@flipfire2.com?subject=Subject&body=Hey, Anas!')}>
                                <Text style={styles.text}>
                                    anas@flipfire2.com
                                </Text>
                            </TouchableOpacity>

                            </Background>

                            <Background style={{flexDirection: 'column', paddingHorizontal: 60}}>
                            
                            <Avatar.Image size={120} style={{marginBottom: 10}} source={require('../../../assets/William.png')} />

                            <Text style={styles.text}>
                                William Apelgren {'\n'} <Text style={{fontStyle: 'italic'}}> Lead Programmer and CEO </Text> {'\n'}
                            </Text>

                            <TouchableOpacity onPress={() => Linking.openURL('mailto:william@flipfire2.com?subject=Subject&body=Hey, William!')}>
                                <Text style={styles.text}>
                                    william@flipfire2.com
                                </Text>
                            </TouchableOpacity>

                            </Background>

                        </View>

                    */