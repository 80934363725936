
import React, { Component } from 'react'

import { isMobileAll as isMobile } from '../../deviceDetect';

import { StyleSheet, Text, View, Dimensions } from 'react-native'

import Background from '../elements/Background'

import { theme } from '../../core/theme'

import { WebView } from 'react-native-webview'; 

export class Suggest extends Component {

  constructor(props){
    super(props);

    this.state = {
    }

  }

  render() {

  return (

    <Background>


          {isMobile
          ?<WebView 
          style={{width: Dimensions.get('screen').width, height:Dimensions.get('screen').height}}
          source={{ html: "<iframe src='https://docs.google.com/forms/d/e/1FAIpQLSc2EZr_jx3yzYeerOX3PhM3Myk3JsDRX6v22Nig1xKBywocTg/viewform?embedded=true' width='640' height='1174' frameborder='0' marginheight='0' marginwidth='0'>Loading...</iframe>" }}
          />
          :<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc2EZr_jx3yzYeerOX3PhM3Myk3JsDRX6v22Nig1xKBywocTg/viewform?embedded=true" width="640" height="1174" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
          }

          <Text style={styles.text}>
            Flipfire2 - Developed in Switzerland 2022©
          </Text>
    
    </Background>
  )
  }
}
export default Suggest

const styles = StyleSheet.create({
  text: {
      color: theme.colors.text,
      fontFamily: 'Helvetica Neue',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 21,
  },
})
