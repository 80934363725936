import React, { Component } from 'react'

import { TouchableOpacity, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'
import firebase from 'firebase'

export class Signup extends Component {

    constructor(props){     //first to be called whenever a component is created
        super(props);
        this.state = {
            email: '',
            password: '',
            name: '',

            nameError: '',
            emailError: '',
            passError: '',

            error: '',
        }
    }

    login(){
      
        this.props.navigation.navigate("Login");
        return
    }

    validName(name){
        if(name){
        }else{
            this.setState({ nameError: "Name can't be empty.", passError: '', emailError: '', error: '' })
            return null
        }
        if (name.match(/^[ A-Ö´`0-9'-., ]+$/i) === null) { 
            this.setState({ nameError: "The name is badly formatted.", passError: '', emailError: '', error: '' })
            return null
        }else{
        }
        return true
    }

    onSignup(){

        const { email, password, name } = this.state;
        
        if(this.validName(name)){
            firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((result) => {
                firebase.firestore().collection("users")
                .doc(firebase.auth().currentUser.uid)
                .set({
                    name,
                    email,
                    creation: firebase.firestore.FieldValue.serverTimestamp()
                })
                this.props.navigation.popToTop();
                result.user.updateProfile({
                    displayName: name
                  })
            })
            .catch((err) => {
                try{
                    if (err.code.includes("email")){
                        this.setState({ emailError: err.message, passError: '', nameError: '', error: '' })
                    }else if (err.code.includes("password")){
                        this.setState({ passError: err.message , emailError: '', nameError: '', error: '' })
                    }else{
                        this.setState({ error: err.message, passError: '', nameError: '', emailError: '' })
                    }
                }catch{
                }
            })
        }else{
        }
    }

    render() {

        const nameError =this.state.nameError;
        const emailError = this.state.emailError;
        const passError = this.state.passError;
        
        const error = this.state.error;
        
        return (
            <Background>

              <BackButton goBack={this.props.navigation.goBack} />

              <Header>Join us!</Header>
  
              <TextInput
                label="Name"
                returnKeyType="next"
                value={this.state.name}
                onChangeText={(name) => this.setState({ name })}
                error={!!nameError}
                errorText={nameError}
              />
              <TextInput
                label="Email"
                returnKeyType="next"
                value={this.state.email}
                onChangeText={(email) => this.setState({ email })}
                error={!!emailError}
                errorText={emailError}
                autoCapitalize="none"
                autoCompleteType="email"
                textContentType="emailAddress"
                keyboardType="email-address"
              />
              <TextInput
                label="Password"
                returnKeyType="done"
                value={this.state.password}
                onChangeText={(password) => this.setState({ password })}
                error={!!passError}
                errorText={passError}
                secureTextEntry
              />

              {error
                  ?<Text style={styles.error}>{error}</Text>
                  : null
              }
                 
              <Button
                mode="contained"
                onPress={() => this.onSignup()}
                style={{ marginTop: 24 }}
              >
                Create Account
              </Button>
              <View style={styles.row}>
                <Text>Already have an account? </Text>
                <TouchableOpacity onPress={() => this.login()}>
                  <Text style={styles.link}>Login</Text>
                </TouchableOpacity>
              </View>

              <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
                    <Text style={styles.text}>
                    Flipfire2 - Developed in Switzerland 2022©
                    </Text>
                </View>

            </Background>
        )
    }
}
        
const styles = StyleSheet.create({
    row: {
    flexDirection: 'row',
    marginTop: 4,
    },
    link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    },
    error: {
        fontSize: 13,
        color: theme.colors.error,
        paddingTop: 8,
    },
    text: {
        color: theme.colors.text,
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 21,
    },
})



export default Signup