import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { theme } from '../../core/theme'

import { isMobileAll as isMobile } from '../../deviceDetect';

export default function Paragraph(props) {
  return <Text style={styles.text} {...props} />
}

const styles = StyleSheet.create({
  text: {
    fontSize: (isMobile ? 20 : 30),
    fontWeight: 'bold',
    lineHeight: (isMobile ? 30 : 42),
    textAlign: 'center',
    fontFamily: 'Helvetica Neue',
    color: theme.colors.surface
  },
})
