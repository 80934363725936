import { DefaultTheme } from 'react-native-paper'

export const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    surface: '#002239', //normal text and dark buttons
    surface2: '#FCFCFC', //depreciated
    text: '#ffffff', //all vit text
    textdark: '#000', //depreciated
    primary: '#FF8F1F', //organge theme
    secondary: '#23B84D', //green theme
    in: '#28D158', //IN
    error: '#FF453A', //OUT
    placeholder: '#ffffff', //depreciated
    success: '#28D158',
    loading: '#2DABFF'
  },
}
