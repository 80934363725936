
import React, { Component } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import CardFlip from 'react-native-card-flip';


const toggleStatus = () => {
  Flip.status = !Flip.status
  console.log('flipped', Flip.status)
}

const Card = (status) => {

  if (status){
    return(

        <View style={styles.container}>

              
              <CardFlip style={styles.cardContainer} ref={card => (Flip.card = card)} onFlipEnd={() => toggleStatus()}>

              <TouchableOpacity
                activeOpacity={1}
                style={[styles.card, styles.in]}
                onPress={() => Flip.card.flip()}>
                <Text style={styles.label}>IN: TRUE 1</Text>
                  </TouchableOpacity>

              <TouchableOpacity
                activeOpacity={1}
                style={[styles.card, styles.out]}
                onPress={() => Flip.card.flip()}>
                <Text style={styles.label}>OUT: FALSE 0</Text>
              </TouchableOpacity>

              </CardFlip>

            </View>
    )
  }else{

      return(

      <View style={styles.container}>

        <CardFlip style={styles.cardContainer} ref={card => (this.card = card)} onFlipEnd={() => toggleStatus()}>

        <TouchableOpacity
          activeOpacity={1}
          style={[styles.card, styles.out]}
          onPress={() => this.card.flip()}>
          <Text style={styles.label}>OUT: FALSE 0</Text>
        </TouchableOpacity>

        <TouchableOpacity
          activeOpacity={1}
          style={[styles.card, styles.in]}
          onPress={() => this.card.flip()}>
          <Text style={styles.label}>IN: TRUE 1</Text>
            </TouchableOpacity>

        </CardFlip>
        
      </View>

      )}


}

export class Flip extends Component {
    constructor(props){     //first to be called whenever a component is created
        super(props);
        this.state = {
          status: false,
          showModal: false,
        }
    }

    render(){


      return(

        <View>
          
          <Card
          status={this.status}/>

      </View>

      )
    
    }
  }
  
export default Flip;
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F5FCFF',
    },
    cardContainer: {
      width: 320,
      height: 470,
    },
    card: {
      width: 320,
      height: 470,
      backgroundColor: '#000',
      borderRadius: 5,
      shadowColor: 'rgba(0,0,0,0.5)',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.5,
    },
    in: {
      backgroundColor: '#01cb08',
    },
    out: {
      backgroundColor: '#FE474C',
    },
    label: {
      lineHeight: 470,
      textAlign: 'center',
      fontSize: 30,
      fontFamily: 'System',
      color: '#ffffff',
      backgroundColor: 'transparent',
    },
  });

        

    