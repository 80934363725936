import React, { Component } from 'react'

import { TouchableOpacity, StyleSheet, View, Dimensions } from 'react-native'
import { Text, ActivityIndicator } from 'react-native-paper'

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase' 

export class ResetPass extends Component {

    constructor(props){     //first to be called whenever a component is created
        super(props);

        this.state = {
            newPassword: '',
            passError: '',

            actionCodeVerified: false,
            loading: true,

            oobCode: this.props.route.params.oobCode,

            email: '',
            done: false, 
            
        }
    }

    verify(){
        firebase.auth().verifyPasswordResetCode(this.state.oobCode)
            .then((email) => {
                this.setState({actionCodeVerified: true, email: email})
            }).catch((err) => {
            }).finally(()=>
                this.setState({loading: false})
            )
    }

    reset(){
        this.setState({ loading: true })
        firebase.auth().confirmPasswordReset(this.state.oobCode, this.state.newPassword)
            .then((result) => {
                this.setState({done: true})
            }).catch((err) => {
                this.setState({ passError: err.message })
            }).finally(()=>
                this.setState({ loading: false })
            )
    }

    componentDidMount(){
        this.verify()
    }

    render() {

        console.log()

        const passError = this.state.passError

        const verified = this.state.actionCodeVerified
        const loading = this.state.loading

        const email = this.state.email
        const done = this.state.done

        return(

            <Background>

                <BackButton goBack={this.props.navigation.goBack} />

                {loading
                    ?<ActivityIndicator animating={loading} color={theme.colors.loading} />
                    :null
                }

                {verified && done!=true/*&& loading!=true*/ ?
                <View style={styles.container}>

                    <Header>Create a new password</Header>

                    <Text style={styles.itemText}>{email}</Text>

                    <TextInput
                        label="New password"
                        returnKeyType="done"
                        value={this.state.newPassword}
                        onChangeText={(newPassword) => this.setState({ newPassword })}
                        error={!!passError}
                        errorText={passError}
                        secureTextEntry
                    />

                    <Button
                        mode="contained"
                        onPress={() => this.reset()}
                        style={{ marginTop: 16 }}
                    >
                        Confirm new password
                    </Button>

                </View>
                :null}

                {verified!=true && done!=true && loading!=true ?
                <View style={styles.container}>

                    <Header>Oops... </Header>
                    <Text style={styles.error}>Something went wrong! Please try again.</Text>

                </View> 
                :null}

                {done?
                    <View>
                        <Header>All done!</Header>
                        <Text style={styles.success}>Your password has now been reset.</Text>
                    </View>
                :null}

                <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
                    <Text style={styles.text}>
                    Flipfire2 - Developed in Switzerland 2022©
                    </Text>
                </View>

            </Background>
        )
    }
    }

const styles = StyleSheet.create({
    text: {
        color: theme.colors.text,
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 21,
    },
    paragraph: {
        fontSize: 20,
        lineHeight: 21,
        textAlign: 'left',
        marginBottom: 12,
        fontWeight: 'bold',
        fontFamily: 'Helvetica Neue',
        color: theme.colors.primary,
        paddingTop: 30,
    },
    error: {
        fontSize: 13,
        color: theme.colors.error,
        paddingTop: 8,
    },
    success: {
        fontSize: 13,
        color: theme.colors.success,
        paddingTop: 8,
    },
    container: {
        flex: 1,
        padding: 20,
        width: '100%',
        maxWidth: Dimensions.get('screen').width,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemText: {
        color: 'white',
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: '900',
        lineHeight: 21,
    },
})

export default ResetPass
