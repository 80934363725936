
import React, { Component } from 'react'

import { isMobileAll as isMobile } from '../../deviceDetect';

import { StyleSheet, Dimensions, View, Image, Platform, FlatList, Text, TouchableOpacity, ScrollView } from 'react-native'

import { Dialog, Portal, ActivityIndicator, Colors, Banner } from 'react-native-paper';

import NetInfo from '@react-native-community/netinfo';

import 'react-native-gesture-handler';

import * as Clipboard from 'expo-clipboard';
 
import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Paragraph2 from '../elements/Paragraph2'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase'

export class Homeorg extends Component {

  constructor(props){
      super(props);

      this.state = {

        org: props.route.params.org,

        currentUser: props.route.params.currentUser,

        screens: null,

        loading: true,

        showDelete: false,
        pendingDel: null,

        code: '',
        codeError: '',

        showDeleteCode: false,

        copied: false,

        showNetError: false,

      }

  }

  copyCode(){
    try {
      Clipboard.setString(this.state.code);
      this.setState({ copied: true })
    } catch (error) {
      console.log(error, 'SETstring ERROR')
    }
  }
  

  addCode(code){
    if(this.validCode(code)){
      firebase.firestore().collection('orgs').doc(this.state.org.id)
      .update({
        activationcode: code,
      })
      this.setState({ codeError: '' })
    }
  }

  deleteCode(){
      firebase.firestore().collection('orgs').doc(this.state.org.id)
      .update({
        activationcode: '',
      })
      this.setState({ showDeleteCode: false, copied: false })
  }

  validCode(code){
    if(code){
    }else{
        this.setState({ codeError: "Code field can't be empty."})
        return null
    }
    if(code.length > 50){
      this.setState({ codeError: "Code can't be longer than 50 characthers."})
      return null
    }
    if (code.match(/^[A-Öa-ö0-9]+$/) === null) { 
        this.setState({ codeError: "The code is badly formatted."})
        return null
    }
    return true
  }


  async delete(){
    console.log(this.state.pendingDel.id)

    await firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens')
    .doc(this.state.pendingDel.id).collection('cards').get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens')
          .doc(this.state.pendingDel.id).collection('cards').doc(doc.id).delete();
        })
      })

    firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens')
      .doc(this.state.pendingDel.id).delete();

    this.setState({ loading: false, pendingDel: null, code: '' })
  }

  openDelete(item){
    this.setState({ showDelete: true, pendingDel: item })
  }

  createNewScreen(){
    this.props.navigation.navigate("Newscreen", { currentUser: this.state.currentUser, org: this.state.org });
    return
  }

  goToScreen(screen){
    this.props.navigation.navigate("Homescreen", { org: this.state.org, screen: screen });
  }

 loadOrg(){
    firebase.firestore().collection('orgs').doc(this.state.org.id).get()
      .then(doc => {
        this.setState({ org: {...doc.data(), id: doc.id}, code: doc.data().activationcode })
    })
  }

  async loadScreens(){

    const screens = [];
    await firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').get()
      .then(snapshot => {
        if(!snapshot.empty){ 
          const newProm = new Promise((resolve) => {
            snapshot.docs.forEach(async (doc, index, array) => {
              screens.push({...doc.data(), id: doc.id});
              
              if (index === array.length - 1) resolve();
            })
          });
          newProm.then(() => {
            this.setState({ screens: screens })
            this.setState({ loading: false })
          });
        }else{
          this.setState({ screens: null })
          this.setState({ loading: false })
        }
      })
  }

  componentDidMount(){

    this.unsubscribe = firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').onSnapshot(this.onCollectionUpdate)
    this.unsubscribe2 = firebase.firestore().collection('orgs').doc(this.state.org.id).onSnapshot(this.onDocUpdate)
    this.unsubNet = NetInfo.addEventListener(state => {
      this.setState({ showNetError: !state.isConnected })
    });

  }

  componentWillUnmount() {
    this.unsubscribe()
    this.unsubscribe2()
    this.unsubNet();
  }

  onCollectionUpdate = (querySnapshot) => {
    this.loadScreens()
  }

  onDocUpdate = (querySnapshot) => {
    //console.log('UPDATE')
    this.loadOrg()
  }

  render() {

    const currentUser = this.state.currentUser

    const screens = this.state.screens

    const loading = this.state.loading

    const org = this.state.org

    const showDelete = this.state.showDelete

    const codeError = this.state.codeError

    const showDeleteCode = this.state.showDeleteCode

    const copied = this.state.copied

    const showNetError = this.state.showNetError

    return (

        <Background>

          <Portal>
              <Dialog style={{backgroundColor: theme.colors.surface, width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={showNetError} onDismiss={() => this.setState({ showNetError: false })}>
              <Dialog.Content>
                  <Text style={{fontFamily: 'Helvetica Neue', fontSize: 40, fontWeight: 'bold', color: theme.colors.error}}>No internet connection found! Changes may be lost.</Text>
                  <Button mode="contained" style={{width: (isMobile ? '100%' : '40%'), alignSelf: 'center'}} onPress={() => this.setState({ showNetError: false })}>
                      Noted
                  </Button>
              </Dialog.Content>
              </Dialog>
          </Portal>

          {!isMobile
            ?<BackButton goBack={this.props.navigation.goBack}/>
            :null
          }

          <ScrollView style={{ flex: 1, width: Dimensions.get('screen').width}}>

            <View style={styles.scroll}> 

            {isMobile
            ?<BackButton goBack={this.props.navigation.goBack}/>
            :null
            }
      
        
        {this.state.pendingDel
          ? <Portal>
              <Dialog visible={showDelete} style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} onDismiss={() => this.setState({ showDelete: false })}>
                  <Dialog.Content>

                      <Paragraph>Are u sure you want to delete {this.state.pendingDel.name}? {'\n'}
                        No one will be able to access {this.state.pendingDel.name} any more!
                      </Paragraph>

                  </Dialog.Content>
                  <Dialog.Actions style={{justifyContent: 'center'}}>

                      <Button style={{width: (isMobile ? '30%' : '40%')}} mode="outlined" onPress={() => this.delete()}>YES</Button>
                      <Button style={{width: (isMobile ? '30%' : '40%')}} mode="contained" onPress={() => this.setState({ showDelete: false })}>NOPE</Button>
              
                  </Dialog.Actions>
              </Dialog>
          </Portal>
          :null
        }

        <Portal>
          <Dialog visible={showDeleteCode} style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} onDismiss={() => this.setState({ showDeleteCode: false })}>
              <Dialog.Content>

                  <Paragraph>Are u sure you want to delete the activation code? {'\n'}
                    No addtional users will be able to access this organization. Current users will still have access.
                  </Paragraph>

              </Dialog.Content>
              <Dialog.Actions style={{justifyContent: 'center'}}>

                  <Button style={{width: (isMobile ? '30%' : '40%')}} mode="outlined" onPress={() => this.deleteCode()}>YES</Button>
                  <Button style={{width: (isMobile ? '30%' : '40%')}} mode="contained" onPress={() => this.setState({ showDeleteCode: false })}>NOPE</Button>
              
              </Dialog.Actions>
          </Dialog>
        </Portal>

        <Header>{org.name} screens</Header>

        <View style={{width: (isMobile ? '90%' : '60%'), marginTop: 40}}>
        
        {org.admin === currentUser.uid
        ?[(!isMobile
          ? <Button mode="contained" icon="plus" style={{ width: '20%', height: 50, alignSelf: 'flex-end', borderRadius: 50}} onPress={() => this.createNewScreen()}>
            Add
          </Button>
          :null
          )
        ]
        :null
        }

        </View>
      
        {loading
        ? <ActivityIndicator animating={loading} color={'#28D158'} /> 
        : [
          (!screens
            ? <View style={{backgroundColor: theme.colors.surface, width: (isMobile ? '90%' : '60%'), height: '40%', minHeight: 450, justifyContent: 'center', borderRadius: 7, borderColor: theme.colors.text, borderWidth: 2}}>

              <Paragraph2>
                Looks like {org.name} doesn't have any screens yet!{'\n'}

                {org.admin === currentUser.uid
                ? <Paragraph2>{'\n'}{'\n'}Create one...{'\n'}</Paragraph2>
                : <Paragraph2>{'\n'}{'\n'}Ask your organization to create one.{'\n'}</Paragraph2>
                }
              </Paragraph2> 

              </View>

            : <View style={{backgroundColor: theme.colors.surface, marginBottom: (isMobile ? null : 30), width: (isMobile ? '90%' : '60%'), height: '40%', minHeight: 450, justifyContent: 'center', borderRadius: 7, borderColor: theme.colors.text, borderWidth: 2}}>
              
                <View style={{borderRadius: 4, width: '100%', height: '100%'}}>

                  <FlatList
                    data={screens}
                    keyExtractor={item => item.id}
                    renderItem={({ item }) => (
                      <TouchableOpacity style={[styles.row, {backgroundColor: theme.colors.text, borderRadius: 7, marginVertical: 10, marginHorizontal: 15, minHeight: 60}]} onPress={() => this.goToScreen(item)}>
        
                        <View style={{flex: 1, alignSelf: 'center'}}>
                          <Paragraph>{item.name}</Paragraph> 
                        </View>

                        {org.admin === currentUser.uid
                        ? <TouchableOpacity style={{alignSelf: 'center', marginRight: 10}} onPress={() => this.openDelete(item)}>
                          <Image
                          style={{height: 30, width: 30}}
                          source={require('../../srcassets/trash.png')}
                          />
                        </TouchableOpacity>
                        :null
                        }

                      </TouchableOpacity> 
                    )}

                  />

                </View>

              </View>

            )]
            }

            {org.admin === currentUser.uid
            ? <View style={{alignSelf: 'center', width: (isMobile ? '90%' : '60%')}}>
            
              {isMobile
              ? <Button mode="contained" icon="plus" style={{ width: '45%', height: 45, alignSelf: 'flex-end', justifyContent: 'center', marginHorizontal: 15, borderRadius: 50}} onPress={() => this.createNewScreen()}>
                Add
              </Button>
              :null
              }

              <Header>Add staff using an access code.</Header>

              {org.activationcode
              ?<View style={{alignSelf: 'center', marginTop: 10, marginBottom: 30, borderBottomWidth: 2, borderBottomColor: theme.colors.text}}> 

                <Paragraph2>Others can access {org.name} by using: </Paragraph2>

                <View style={styles.row}>

                  <Text onPress={() => this.copyCode()} style={{flex: 1, textAlign: 'center', color: theme.colors.text, fontSize: 20, fontFamily: 'Helvetica Neue', fontWeight: 'bold'}}>{org.activationcode}</Text>

                  <TouchableOpacity onPress={() => this.setState({ showDeleteCode: true })}>
                    <Image
                    style={{height: 30, width: 30}}
                    source={require('../../srcassets/whitetrash.png')}
                    />
                  </TouchableOpacity>

                </View>

                {copied
                ?<Text style={styles.text}>Copied to clipboard!</Text>
                :null
                }
              
              </View>
              :<View style={{width: '100%', justifyContent: 'center'}}>

                <View style={[(!isMobile ? styles.row : null), {minWidth: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 40}]}>
                
                  <TextInput
                  label="Access code" 
                  returnKeyType="next"
                  value={this.state.code}
                  onChangeText={(code) => this.setState({ code })}
                  error={!!codeError}
                  errorText={codeError}
                  />

                  <Button mode="outlined" style={{ minWidth: (isMobile ? '20%' : '30%' ), justifyContent: 'center', height: (isMobile ? '30%' : '50%')}} onPress={() => this.addCode(this.state.code)}> 
                    Create access code
                  </Button>

                </View>

              </View>
              }
   
            </View>
            :null
            }

        <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
          <Text style={styles.text}>
            Flipfire2 - Developed in Switzerland 2022©
          </Text>
        </View>
      
        </View>
        </ScrollView>

      </Background>

    )
  }
}

export default Homeorg


const styles = StyleSheet.create({
  error: {
      fontSize: 13,
      color: theme.colors.error,
      paddingTop: 8,
  },
  row: {
      flexDirection: 'row',
      marginTop: 4,
  },
  text: {
      color: theme.colors.text,
      fontFamily: 'Helvetica Neue',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 21,
  },
  scroll: {

    flex: 1,
    padding: 20,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',

  }
})