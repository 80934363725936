import React from 'react'
import { ImageBackground, StyleSheet, KeyboardAvoidingView, Dimensions } from 'react-native'
import { theme } from '../../core/theme'

export default function Background({ children}) {
    return (
      <ImageBackground
        source={require('../../srcassets/dot.png')}
        resizeMode="repeat"
        style={styles.background}
      >
        <KeyboardAvoidingView style={styles.container} behavior="padding">
          {children}
        </KeyboardAvoidingView>
      </ImageBackground>
    )
} 

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    backgroundColor: theme.colors.surface,
  },
  container: {
    flex: 1,
    padding: 20,
    width: '100%',
    maxWidth: Dimensions.get('screen').width,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
