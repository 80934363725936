
import React, { Component, Fragment } from 'react'

import Select from 'react-select';

import { isMobileAll as isMobile } from '../../deviceDetect';

import { StyleSheet, Dimensions, View, Image, Platform, FlatList, Text, TouchableOpacity, ImageBackground, SnapshotViewIOSBase } from 'react-native'

import { Dialog, Portal, ActivityIndicator, Colors, Banner } from 'react-native-paper';

import 'react-native-gesture-handler';

import * as DocumentPicker from 'expo-document-picker';
 
import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Paragraph2 from '../elements/Paragraph2'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase'

import NumberPlease from "react-native-number-please";
import { ConsoleView } from 'react-device-detect';

import NetInfo from '@react-native-community/netinfo';

export class Homescreen extends Component {

    constructor(props){
        super(props); 

        this.state = {

            screen: props.route.params.screen,

            org: props.route.params.org,

            showImportCard: false,
            showAddCard: false,
            name: '',
            nameError: '',

            sortedCards: null,
            cards: null,
            loading: true,

            importError: false,
            impErrorText: '',
            
            showDelete: false,
            pendingDel: null,

            mobile: false,

            code: null,
            vals: null,

            showEdit: false,
            pendingEdit: null,
            pendingName: null,
            pendingGroup: null,
            hasGroup: null,

            showNetError: false,

            sortOpt: null,
            rerenderOpt: true,

        }
    } 

    getCode(snapshot){
        try {
            const code = snapshot.data().code
            this.setState({ code: code })
            this.setState({ vals: [{id: "num1", value: code.charAt(0) },{ id: "num2", value: code.charAt(1) },{ id: "num3", value: code.charAt(2) },{ id: "num4", value: code.charAt(3)}] })
            } catch (error) {
            this.setState({ vals: null })
        }
    }

    updateCode(codeList){
        var newCode = ''
        /*const newcodeList = */codeList.map((n) => newCode = newCode + n.value)
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).update({
            code: newCode
        })
        this.setState({ code: newCode })
    } 

    async uploadCSV(){
        let result = await DocumentPicker.getDocumentAsync({type: 'text/csv'})
        if (!result.cancelled) {
            let reader = new FileReader();
             reader.onload = () => {
                //console.log(reader.result)
                const string = reader.result
                const names = string.split(/[/\r\n/]+/)
                //console.log(string, names, names.length)
                for(let i=0; i< names.length; i++){
                    if(!this.validName(names[i], {import: true})){
                        this.setState({ importError: true })
                        return null
                    }else{
                    }
                }
                this.setState({ loading: true })
                for(let i=0; i< names.length; i++){
                    this.uploadCard(names[i])
                }
                this.setState({ loading: false })
            }
            reader.readAsText(result.file)
        }
      };

    goToFlipScreen(){
        this.props.navigation.navigate("Flipscreen", { org: this.state.org, screen: this.state.screen, code: this.state.code, /* sortOpt: this.state.sortOpt*/ });
        return
    }

    flip(card){
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id)
            .collection('cards')
            .doc(card.id)
            .update({
                status: !card.status
            })
    }

    validName(name, impError){
        if(name){
        }else{
            this.setState({ nameError: "Name can't be empty."})
            if(impError){
                this.setState({ impErrorText: "One or more rows are empty. Try export .csv again." })
            }
            return null
        }
        if(name.length > 50){
            this.setState({ nameError: "Name can't be longer than 50 characthers."})
            if(impError){
                this.setState({ impErrorText: "One or more names have more than 50 characthers." })
                //hello
            }
            return null
        }
        if(name.match(/^[ A-Ö'-., ]+$/i) === null) { 
            this.setState({ nameError: "The name is badly formatted."})
            if(impError){
                this.setState({ impErrorText: "One or more names contain bad characthers like #,*,$,&,/,=, etc. Try export .csv again." })
            }
            return null
        }
        return true  
    }

    openDelete(item){
        this.setState({ showDelete: true, pendingDel: item })
    }

    openEdit(item){
        this.setState({ showEdit: true, pendingEdit: item, pendingName: item.name, pendingGroup: item.group, hasGroup: item.group })
    }

    delete(){
        this.setState({ loading: true, openDelete: false })
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id)
            .collection('cards')
            .doc(this.state.pendingDel.id)
            .delete();
        this.setState({ loading: false, pendingDel: null, sortedCards: null }) //sorted cards cus no db cards returned hence no change of sortedCards list (if no cards in database).
    }

    edit(){
        const name = this.state.pendingName
        const group = this.state.pendingGroup
        console.log(name, group)
        if(this.validName(name)){
            this.editCard(name, group)
        }
    }

    editCard(name, group){
        const card = this.state.pendingEdit
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id)
            .collection('cards')
            .doc(card.id)
            .update({
                name: name,
                group: group
        })
        this.setState({ showEdit: false, nameError: '' })
    }

    uploadCard(){
        const name = this.state.name
        if(this.validName(name)){
            console.log('uploading cards...')
            this.setState({ loading: true })
            firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id)
                .collection('cards')
                .doc(Math.random().toString(36))
                .set({
                name,
                creation: firebase.firestore.FieldValue.serverTimestamp(),
                status: true,
                group: '',
                }).then((result) => {
                    this.setState({ loading: false })
                }).catch((err) => {
                    /*this.setState({ nameError: 'Oops... Something went wrong!', importError: true, impErrorText: err })*/
                })
                this.setState({ showAddCard: false, name: '', nameError: ''}
        )}
    }

    getCards(snapshot){
        const cards = [];
        /*firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).collection('cards').get()
            .then(snapshot => {*/
                if(!snapshot.empty){ 
                const newProm = new Promise((resolve) => {
                    snapshot.docs.forEach((doc, index, array) => {
                    cards.push({...doc.data(), id: doc.id});
                    
                    if (index === array.length - 1){
                        resolve()
                    }
                }) 
                });
                newProm.then(() => {
                    this.setState({ cards: cards, loading: false })

                    if(this.state.sortOpt === null){
                        console.log("finns ingen sort opt!")
                        this.getSort()
                    }else{
                        console.log("kortleken ändras...")
                        this.setState({ sortedCards: this.sortCards() })
                    }
                })
                }else{
                    this.setState({ loading: false, cards: null })
                } 
        //})
    }

    componentDidMount(){
        this.unsubscribe = firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).collection('cards').onSnapshot(this.onCollectionUpdate)
        this.unsubscribe2 = firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).onSnapshot(this.onDocUpdate)

        this.unsubNet = NetInfo.addEventListener(state => {
            this.setState({ showNetError: !state.isConnected }) 
        });
    }

    componentWillUnmount() {
        this.unsubscribe()
        this.unsubscribe2()
        this.unsubNet();
    }

    onCollectionUpdate = (querySnapshot) => {
        console.log("change in COLLECTION...")
        this.getCards(querySnapshot)
    }

    onDocUpdate = (querySnapshot) => {
        console.log("change in DOC...")
        this.getCode(querySnapshot)

        const foreignOpt = querySnapshot.data().sortOption
        if(foreignOpt != this.state.sortOpt && this.state.sortOpt != null && this.state.sortedCards){
            var rerender = false
            this.setState({ rerenderOpt: false })
            this.setState({ sortOpt: foreignOpt, rerenderOpt: true })
            this.setState({ sortedCards: this.sortCards() })
        }else{
            rerender = true
        }

        if(this.state.sortOpt != null && this.state.sortedCards && rerender){
            this.setState({ sortedCards: this.sortCards() })
        }
    }
 
    getSort(){
        console.log("getting sort method...")
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).get()
        .then(snapshot => {
            if(snapshot.data().sortOption){
                try {
                    const option = snapshot.data().sortOption
                    this.setState({ sortOpt: option })
                } catch (error) {
                }
            }else{
                this.setState({ sortOpt: 0 })
                firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).update({
                    sortOption: 0
                })
            }
            this.setState({ sortedCards: this.sortCards() })
        })
    }

    updateSort(obj){ 
        console.log("updating sort method...")
        const newOpt = obj.value
        this.setState(() => ({ sortOpt: obj.value }))
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).update({
            sortOption: newOpt
        })
        this.setState({ sortOpt: newOpt })
    }

    sortCards(){
        console.log("sorting cards...")
        try {
            const opt = this.state.sortOpt
            console.log(opt, "sort option")
            const cards = this.state.cards.sort((a, b) => (opt===0?(a.name > b.name):opt===1?(a.group > b.group):(a.status > b.status)) ? 1 : -1)
            return cards
        } catch (error){
        }
    }

    options = [
        { value: 0, label: 'Name' },
        { value: 1, label: 'Group' },
        { value: 2, label: 'Status' },
    ]

    render() {

        console.log("RENDERING...")

        const screen = this.state.screen;

        const showAddCard = this.state.showAddCard;

        const nameError = this.state.nameError;

        const loading = this.state.loading;

        const importError = this.state.importError;

        const showDelete = this.state.showDelete;

        const vals = this.state.vals;

        const showEdit = this.state.showEdit;

        const impErrorText = this.state.impErrorText;

        const DATE_OPTIONS = { weekday: 'short', /*year: 'numeric',*/ month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

        const showNetError = this.state.showNetError

        const sortOpt = this.state.sortOpt

        const sortedCards = this.state.sortedCards

        const rerenderOpt = this.state.rerenderOpt

        return (
    
          <Background>

            <Portal>
                <Dialog style={{backgroundColor: theme.colors.surface, width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={showNetError} onDismiss={() => this.setState({ showNetError: false })}>
                <Dialog.Content>
                    <Text style={{fontFamily: 'Helvetica Neue', fontSize: 40, fontWeight: 'bold', color: theme.colors.error}}>No internet connection found! Changes may be lost.</Text>
                    <Button mode="contained" style={{width: (isMobile ? '100%' : '40%'), alignSelf: 'center'}} onPress={() => this.setState({ showNetError: false })}>
                        Noted
                    </Button>
                </Dialog.Content>
                </Dialog>
            </Portal>

            <Portal>
                <Dialog visible={importError} style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} onDismiss={() => this.setState({ importError: false })}>
                    
                    <Dialog.Content>
                        <Paragraph>{impErrorText}</Paragraph>
                    </Dialog.Content>
                    
                    <Dialog.Actions style={{justifyContent: 'center'}}>
                        <Button style={{width: (isMobile ? '30%' : '40%')}} mode="outlined" onPress={() => this.setState({ importError: false })}>OK</Button>
                    </Dialog.Actions> 
                </Dialog>
            </Portal>

            {this.state.pendingDel
            ? <Portal>
                <Dialog visible={showDelete} style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} onDismiss={() => this.setState({ showDelete: false })}>
                    <Dialog.Content>

                        <Paragraph>Are you sure you want to delete {this.state.pendingDel.name}?</Paragraph>
                    
                    </Dialog.Content>
                    <Dialog.Actions style={{justifyContent: 'center'}}
                    >
                        <Button style={{width: (isMobile ? '30%' : '40%')}} mode="outlined" onPress={() => this.delete()}>Yes</Button>
                        <Button style={{width: (isMobile ? '30%' : '40%')}} mode="contained" onPress={() => this.setState({ showDelete: false })}>Cancel</Button>

                    </Dialog.Actions>
                </Dialog>
            </Portal>
            :null
            }

            {this.state.pendingEdit
            ? <Portal>
                <Dialog visible={showEdit} style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} onDismiss={() => this.setState({ showEdit: false })}>
                    <Dialog.Content>

                        <Paragraph>Edit {this.state.pendingEdit.name}</Paragraph>

                        <TextInput
                        label="New name"
                        returnKeyType="next"
                        value={this.state.pendingName}
                        onChangeText={(pendingName) => this.setState({ pendingName })}
                        error={!!nameError}
                        errorText={nameError}
                        />

                        <TextInput
                        label={this.state.hasGroup?"New group":"Add group"}
                        returnKeyType="next"
                        value={this.state.pendingGroup}
                        onChangeText={(pendingGroup) => this.setState({ pendingGroup })}
                        error={null}
                        errorText={null}
                        />

                    </Dialog.Content>
                    <Dialog.Actions style={{justifyContent: 'center'}}
                    >
                        <Button style={{width: (isMobile ? '30%' : '40%')}} mode="outlined" onPress={() => this.edit()}>Save</Button>
                        <Button style={{width: (isMobile ? '30%' : '40%')}} mode="contained" onPress={() => this.setState({ showEdit: false })}>Cancel</Button>

                    </Dialog.Actions>
                </Dialog>
            </Portal>
            :null
            }
    
            <Portal>
                <Dialog style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={showAddCard} onDismiss={() => this.setState({ showAddCard: false, nameError: '' })}>
                
                <ImageBackground
                source={require('../../srcassets/doubledot.png')}
                resizeMode="repeat"
              
                style={{width: '100%', height: '100%'}}
              >

                <Dialog.Content>

                    <View style={{width: (isMobile ? '100%' : '130%'), alignSelf: 'center', paddingTop: 15}}>
                        <TextInput
                        label="Name"
                        returnKeyType="next"
                        value={this.state.name}
                        onChangeText={(name) => this.setState({ name })}
                        error={!!nameError}
                        errorText={nameError}
                        />
                    </View>

                <Button mode="outlined" style={{width: (isMobile ? '100%' : '40%'), alignSelf: 'center'}} onPress={() => this.uploadCard()}>
                    Add
                </Button>

                </Dialog.Content>

                </ImageBackground>
                </Dialog>
            </Portal>
     
            <BackButton goBack={this.props.navigation.goBack} />
    
            <Header>{screen.name} screen</Header>

            {isMobile
            ?null
            :<View style={{flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', width: '90%'}}>

            <Button mode="contained" style={{ marginVertical: 30, marginHorizontal: 10, alignSelf: 'center', width: '25%'}} onPress={() => this.setState({ showAddCard: true })}>
            Add card
            </Button>

            <Button mode="contained" style={{ marginVertical: 30, marginHorizontal: 10, alignSelf: 'center', width: '25%'}} onPress={() => this.uploadCSV()}>
            Import {!isMobile ? 'cards' : null} 
            </Button>

            <Button mode="contained" style={{ marginVertical: 30, marginHorizontal: 10, alignSelf: 'center', width: '25%'}} onPress={() => this.goToFlipScreen()}>
            Display mode
            </Button>

            {sortOpt!=null && rerenderOpt ?
            <View style={{alignSelf: 'flex-end', flex: 1, justifyContent: 'space-around', zIndex: 1, marginBottom: 10}}>

                <Paragraph2>
                    Sort by:
                </Paragraph2>

                <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={this.options[sortOpt]}
                isDisabled={!sortedCards}
                isLoading={loading}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name="options"
                options={this.options}
                onChange={(obj) => this.updateSort(obj)} 
                />
            
            </View>
            :null}

            {vals ?
            <View style={{alignSelf: 'flex-end', flex: 1, justifyContent: 'space-around', marginBottom: 10}}>
                <Paragraph2>
                    Exit-code:
                </Paragraph2>

         
                <NumberPlease
                    digits={[{ id: "num1", label: "", min: 0, max: 9 },{ id: "num2", label: "", min: 0, max: 9 },{ id: "num3", label: "", min: 0, max: 9 },{ id: "num4", label: "", min: 0, max: 9 }]}
                    values={vals}
                    onChange={(values) => this.setState({ vals: values}) & this.updateCode(values)}
                />
            </View>
            :null}
            
            </View> 
            }
        
            <View style={{ zIndex:-10, marginTop: (isMobile ? 40  : null), alignSelf: 'center', backgroundColor: theme.colors.surface, marginBottom: (isMobile ? null : 30), width: (isMobile ? '90%' : '80%'), height: Dimensions.get('window').height * 0.7, minHeight: 450, justifyContent: 'center', borderRadius: 7, borderColor: theme.colors.text, borderWidth: 2}}>
                
                <View style={{borderRadius: 4, width: '100%', height: '100%'}}>

                    {loading
                    ?<ActivityIndicator animating={loading} color={theme.colors.loading} />
                    :null
                    }

                    {!loading && !sortedCards
                        ?<View style={{justifyContent: 'center', paddingHorizontal: 10, flex: 1}}>
                            <Paragraph2>No cards yet. Add some...</Paragraph2>
                        </View>
                        :<FlatList
                        data={sortedCards}
                        scrollEnabled={true}
                        keyExtractor={item => item.id}
                        renderItem={({ item, index}) => (
                        <View style={[styles.row, {backgroundColor: theme.colors.text, borderRadius: 3, marginHorizontal: 10, height: 50, borderBottomWidth: 2}]}>
                            
                            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                
                                <TouchableOpacity style={[item.status ? styles.in : styles.out, {justifyContent: 'center', borderTopLeftRadius: 3, borderBottomLeftRadius: 3}]} onPress={() => this.flip(item)}>
                                    <Paragraph>{item.status ? 'IN' : 'OUT'}</Paragraph>
                                </TouchableOpacity>

                                <View style={{flex:1, alignSelf:'center'}}>
                                    <Text style={styles.nameText}>{item.name}</Text> 
                                </View>

                                <View style={{flex:1}}>
                                    <Text style={{textAlign: 'center'}}>{(item.group?'Group: '+item.group:null)}</Text> 
                                </View>

                                {isMobile?
                                null
                                :<View style={{flex:1}}>
                                {item.latestFlip ? <Text style={{alignSelf: 'flex-end'}}>Latest flip: {(new Date(item.latestFlip)).toLocaleDateString('en-US', DATE_OPTIONS)}</Text> :null}
                                </View>
                                }

                                <TouchableOpacity style={{alignSelf: 'center', marginLeft: 10}} onPress={() => this.openEdit(item)}>
                                    <Image
                                    style={{height: 30, width: 30}}
                                    source={require('../../srcassets/edit.png')}
                                    />
                                </TouchableOpacity>

                                <TouchableOpacity style={{alignSelf: 'center', marginLeft: 10}} onPress={() => this.openDelete(item)}>
                                    <Image
                                    style={{height: 30, width: 30}}
                                    source={require('../../srcassets/trash.png')}
                                    />
                                </TouchableOpacity>

                            </View>

                        </View>
                        )}
                        />
                    }
                </View>
            </View>

            {isMobile
            ?<View style={{flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', minWidth: '100%'}}>
            <Button mode="contained" icon="plus" style={{ marginTop: 10, marginBottom: 40, alignSelf: 'flex-end', minWidth: '20%', borderRadius: 50}} onPress={() => this.setState({ showAddCard: true })}>
                Add
            </Button>
            </View>
            :null
            }

            <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
                <Text style={styles.text}>
                    Flipfire2 - Developed in Switzerland 2022©
                </Text>
            </View>

          </Background> 
    
        )
      }
    }
    
export default Homescreen


const styles = StyleSheet.create({
    in: {
        width: '20%',
        height: '100%',
        backgroundColor: theme.colors.error,
    },
    out: {
        width: '20%',
        height: '100%',
        backgroundColor: theme.colors.success,
    },
    row: {
        flexDirection: 'row',
        marginTop: 4,
    },
    text: {
        color: theme.colors.text,
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 21,
    },
    nameText: {
        fontSize: (isMobile ? 20 : 30),
        fontWeight: 'bold',
        lineHeight: (isMobile ? 30 : 42),
        textAlign: 'center',
        fontFamily: 'Helvetica Neue',
        color: theme.colors.surface,
        marginLeft: 10,
      },
  })