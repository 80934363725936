
import React, { Component } from 'react'

import { StyleSheet, Text, View } from 'react-native'

import { ActivityIndicator, Colors } from 'react-native-paper';

import 'react-native-gesture-handler';

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase'

export class Newscreen extends Component {

  constructor(props){
      super(props);

      this.state = {
        currentUser: props.route.params.currentUser,

        screenName: '',
        screenNameError: '',

        error: '',

        org: props.route.params.org,

        loading: false,

      }

  }

  validScreenName(name){
    if(name){
    }else{
        this.setState({ screenNameError: "Screen name can't be empty.", error: '' })
        return null
    }
    if(name.length > 50){
      this.setState({ nameError: "Name can't be longer than 50 characthers."})
      return null
    }
    if (name.match(/^[ A-Ö´`0-9'-., ]+$/i) === null) { 
        this.setState({ screenNameError: "The name is badly formatted.", error: '' })
        return null
    }else{
    }
    this.checkId(name)
  }

  checkId(name){
    this.setState({ loading: true })
    firebase.firestore().collection("orgs").doc(this.state.org.id).collection('screens').where(firebase.firestore.FieldPath.documentId(), "==", name.toUpperCase()).get({source: 'server'})
      .then(snapshot => {
        console.log(snapshot)
          if(snapshot.empty){
            this.create(name)
          }else{
            this.setState({ screenNameError: "This screen exists already.", error: '' })
            this.setState({ loading: false })
            //return null
          }
      })
      .catch((err) => {
        this.setState({ screenNameError: "", error: 'Oops... Something went wrong!' })
        this.setState({ loading: false })
      });
  }

  create(name){

    const id = name.toUpperCase()

    firebase.firestore().collection('orgs').doc(this.state.org.id)
        .collection('screens')
        .doc(id)
        .set({
          name,
          creation: firebase.firestore.FieldValue.serverTimestamp(),
          code: '0000',
          sortOption: 0
        })

    this.props.navigation.goBack();
  }

  componentDidMount(){ //whenever component mounts and will render next
  }

  render() {

    const screenNameError = this.state.screenNameError;

    const error = this.state.error;

    const org = this.state.org;

    const loading = this.state.loading;

    return (

      <Background >

        <BackButton goBack={this.props.navigation.goBack} />
  
        <Header>Create new screen for {org.name}</Header>

        {error
          ?<Text style={styles.error}>{error}</Text>
          : null
        }

        <TextInput
            label="Screen name"
            returnKeyType="next"
            value={this.state.screenName}
            onChangeText={(screenName) => this.setState({ screenName })}
            error={!!screenNameError}
            errorText={screenNameError}
        />

        {loading
        ? <Button mode="outlined" style={styles.disabled}> 
          Loading...
            <ActivityIndicator animating={loading} color={'#28D158'} /> 
          </Button> 
        : <Button mode="outlined" onPress={() => this.validScreenName(this.state.screenName)}> 
          Create
          </Button>
        }

        <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
            <Text style={styles.text}>
              Flipfire2 - Developed in Switzerland 2022©
            </Text>
          </View>
          
        </Background>

    )
  }
}

export default Newscreen

const styles = StyleSheet.create({
  error: {
      fontSize: 13,
      color: theme.colors.error,
      paddingTop: 8,
  },
  disabled: {
    opacity: 0.25,
  },
  text: {
    color: theme.colors.text,
    fontFamily: 'Helvetica Neue',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 21,
  },
})