import React, { Component } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, StatusBar } from 'react-native'

import { Provider } from 'react-native-paper'

import firebase from 'firebase' //do seperate imports when production

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Home from './components/main/Home'
import Flip from './components/main/Flip'
import NewOrgScreen from './components/main/Neworg'
import HomeOrgScreen from './components/main/Homeorg'
import NewScreen from './components/main/Newscreen'
import HomeScreen from './components/main/Homescreen'
import FlipScreen from './components/main/Flipscreen'
import PrivacyScreen from './components/main/Privacy'

import LoginScreen from './components/auth/Login'
import SignupScreen from './components/auth/Signup'
import Reset from './components/auth/Reset'
import ResetPass from './components/auth/ResetPass'

import { theme } from './core/theme'

import * as Linking from 'expo-linking';

const firebaseConfig = {
  apiKey: "AIzaSyCpY_QHqWNBIFavblhnIsRLTO8htEWrsz0",
  authDomain: "flipfire2app.firebaseapp.com",
  projectId: "flipfire2app",
  storageBucket: "flipfire2app.appspot.com",
  messagingSenderId: "415411304565",
  appId: "1:415411304565:web:67698611c06ff6ee3b603c",
  measurementId: "G-4CDHQS5F9G"
};

if(firebase.apps.length === 0){
  firebase.initializeApp(firebaseConfig)
}

try {
  firebase.analytics().logEvent('Opened App (EGEN LOG)', { 
    time: new Date().getTime(),
  });
} catch (error) {
}

StatusBar.setBarStyle('dark-content')
const Stack = createStackNavigator(); //our screens, routs and tag for every route

export class App extends Component {
  constructor(props){     //first to be called whenever a component is created
    super(props);
    this.state = {
      loaded: false,
      //linking: {prefixes: [Linking.createURL('/'), 'https://flipfire2.com']}
    }
  }

  linking = {
    prefixes: [Linking.createURL('/'), 'https://flipfire2.com'],
    config: {
      initialRouteName: "Home",
      screens: {  
        Privacyscreen: { 
          path: "Privacy"
        },
        ResetPass: {
          path: "Resetpassword"
        },
      } 
    }
  };

  componentDidMount(){   //whenever component mounts and will render next   
    firebase.auth().onAuthStateChanged((user) => {
      if(!user){
        this.setState({ 
          loaded: true,
          user: false,
        })
      }else{
        this.setState({
          loaded: true, 
          user: true,
        })
      }
    })
    
  }

  render(){

    const loaded = this.state;

    if(!loaded){
      return(
        <View style={{ flex: 1 , justifyContent: 'center'}}>
          <Text>Loading...</Text>
        </View>
      );
    }
      return (
        <Provider theme={theme}>
          <NavigationContainer linking={this.linking} fallback={<Text>Loading...</Text>}>
            <Stack.Navigator
            initialRoutname="Home"
            screenOptions={{
              headerStyle: {
                backgroundColor: '#ffffff',
              },
              title: 'Flipfire2',
            }}
          >
            <Stack.Screen name="Home" component={Home} options={{ headerShown: false}}/>

            <Stack.Screen name="Flip" component={Flip} options={{ headerShown: false}}/>

            <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false}}/> 

            <Stack.Screen name="Signup" component={SignupScreen} options={{ headerShown: false}}/>

            <Stack.Screen name="Reset" component={Reset} options={{ headerShown: false}}/>

            <Stack.Screen name="Neworg" component={NewOrgScreen} options={{ headerShown: false}}/> 

            <Stack.Screen name="Homeorg" component={HomeOrgScreen} options={{ headerShown: false}}/> 

            <Stack.Screen name="Newscreen" component={NewScreen} options={{ headerShown: false}}/> 

            <Stack.Screen name="Homescreen" component={HomeScreen} options={{ headerShown: false}}/>

            <Stack.Screen name="Flipscreen" component={FlipScreen} options={{ headerShown: false}}/>

            <Stack.Screen name="Privacyscreen" component={PrivacyScreen} options={{ headerShown: false}}/>

            <Stack.Screen name="ResetPass" component={ResetPass} options={{ headerShown: false}}/>

            </Stack.Navigator> 
          </NavigationContainer>
        </Provider>
      );
  }
}

export default App;