import React, { Component } from 'react'
import { StyleSheet, Dimensions, View, Image, Platform, FlatList, Text, TouchableOpacity, ImageBackground } from 'react-native'

import { Dialog, Portal, ActivityIndicator, Colors, Banner } from 'react-native-paper';

import CardFlip from 'react-native-card-flip';
import 'react-native-gesture-handler';
import Paragraph from '../elements/Paragraph'
import BackButton from '../elements/BackButton'
import Header from '../elements/Header'
import Button from '../elements/Button'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'
import firebase from 'firebase'

import { isMobileAll as isMobile } from '../../deviceDetect';

import { activateKeepAwake, deactivateKeepAwake } from 'expo-keep-awake';

import NetInfo from '@react-native-community/netinfo';
import { isThursday } from 'date-fns';
import { arDZ } from 'date-fns/locale';

//import * as Speech from 'expo-speech';

export class Flipscreen extends Component {

    constructor(props){
        super(props);

        this.state = {

            screen: props.route.params.screen,

            org: props.route.params.org,

            //sortOpt: props.route.params.sortOpt,
            sortOpt: null,
            sortedCards: null,
            cards: null,

            loading: true,

            showNetError: false,

            showEnterCode: false,

            code: props.route.params.code,
            exitcode: '',
            exitError: '',

            editKeys: false,
            currentEdit: null,
            editKeyError: false,
        
        }
    }
    
/*
    async speak(name){
        var newName = name.split(" ")[0]
        var hours = new Date().getHours();
        var time = 0
        if(hours<11){
            time = 0
        }else if(hours>11 && hours<18){
            time = 1
        }else{
            time = 2
        }
        if(!await Speech.isSpeakingAsync()){
            Speech.speak("Good" + (time===0 ? "morning " :(time===1 ? "afternoon " : "eevening ")) + newName, {language: 'en-Gb', rate: 1})
        }
    }
*/

    logClick(){
        try {
            firebase.analytics().logEvent('CARD FLIP (EGEN LOG)', { 
              time: new Date().getTime(),
              screen: this.state.screen.name,
              org: this.state.org.name
            });
          } catch (error) {
        }
    }

    askCode(mode){
        if(this.state.code){
            this.setState({ showEnterCode: true, codeMode: mode })
        }else{
            firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id)
            .update({
                code: '0000'
            })
            if(!mode){
                this.props.navigation.goBack()
            }
        }
    }

    confCode(){
        //console.log(this.state.exitcode, this.state.code)
        if(this.state.exitcode === this.state.code){
            this.setState({ showEnterCode: false, exitcode:'' })
            return true
        }else{
            this.setState({ exitError: 'This code does not match the right exit-code' })
            return false
        }
    }

    exit(){
        if(this.confCode()){
            this.props.navigation.goBack()
        }
    }

    addKeys(){ //editkeys
        if(this.confCode()){
            this.setState({editKeys: true, edited: []})
        }
    }

    toggleStatus(card){
        console.log("toggleing", card.id, card.status)
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id)
            .collection('cards')
            .doc(card.id)
            .update({
                status: !card.status,
                latestFlip: new Date().getTime()
        })
        if(this.state.editKeys){
            const newEdited = this.state.edited + card.id
            this.setState({ edited: newEdited, currentEdit: card })
        }
        card.status = !card.status
        //this.speak(card.name);
    }

    getCards(snapshot){
        const cards = []; 
        /*firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).collection('cards').get()
        .then(snapshot => {*/
            if(!snapshot.empty){ 
            const newProm = new Promise((resolve) => {
                snapshot.docs.forEach((doc, index, array) => {
                cards.push({...doc.data(), id: doc.id});
                
                if (index === array.length - 1) resolve();
                })
            }); 
            newProm.then(() => {
                this.setState({ /*cards: null,*/ cards: cards, loading: false })

                if(this.state.sortOpt === null){
                    console.log("finns ingen sort opt!")
                    this.getSort()
                }else{
                    console.log("kortleken ändras...")
                    this.setState({ sortedCards: null })
                    this.setState({ sortedCards: this.sortCards() })
                }

            });
            }else{
            this.setState({ cards: null, loading: false, sortOpt: 0 })
            }
        //})
    }

    componentDidMount(){
        this.unsubscribe = firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).collection('cards').onSnapshot(this.onCollectionUpdate)
        this.unsubscribe2 = firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).onSnapshot(this.onDocUpdate)

        this.unsubNet = NetInfo.addEventListener(state => {
            this.setState({ showNetError: !state.isConnected })
        });
        activateKeepAwake();

        var keyid = ''
        this.idSense = addEventListener('keydown', (event) => {
            if(event.key != "Enter"){
                keyid += event.key
            }else{
                this.regID(keyid)
                keyid = ''
            }
        })
    }

    regID(id){
        console.log(id)
        if(this.state.editKeys){
            if(!this.state.currentEdit){
                this.setState({editKeyError: true})
            }else{
                this.linkCardKey(id)
            }
        }else{
            this.flipUsingKey(id)
        }
    }

    linkCardKey(id){
        const editing = this.state.currentEdit
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).collection('cards').doc(editing.id).update({
            key: id
        })
        this.toggleStatus(editing)
        this.setState({ currentEdit: null })
    }

    flipUsingKey(key){
        console.log('flipping using key')
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).collection("cards").where("key", "==", key).limit(1).get()
        .then(snapshot => {
            if(!snapshot.empty){
                const id = snapshot.docs[0].id
                const status = snapshot.docs[0]._delegate._document.data.value.mapValue.fields.status.booleanValue
                console.log(id, status)
                const card = {id: id, status: status}
                this.toggleStatus(card)
            }else{
                console.log("no card with this ID")
            }
        })
    }

    deleteKey(item){
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).collection('cards').doc(item.id).update({
            key: null
        })
    }

    componentWillUnmount() {
        deactivateKeepAwake();
        this.unsubscribe()
        this.unsubscribe2()
        this.unsubNet();
        //this.idSense(); //// FIXA UNSUB!!!
    }

    onCollectionUpdate = (querySnapshot) => {
        this.getCards(querySnapshot)
    }

    onDocUpdate = (querySnapshot) => { 

        const foreignOpt = querySnapshot.data().sortOption
        if(foreignOpt != this.state.sortOpt && this.state.sortOpt != null && this.state.sortedCards){
            var rerender = false
            this.setState({ sortOpt: foreignOpt, sortedCards: null })
            this.setState({ sortedCards: this.sortCards() })
        }else{
            rerender = true
        }

        if(this.state.sortOpt != null && this.state.sortedCards && rerender){
            this.setState({ sortedCards: null })
            this.setState({ sortedCards: this.sortCards() }) 
        }
    }
 
    getSort(){
        console.log("getting sort method...")
        firebase.firestore().collection('orgs').doc(this.state.org.id).collection('screens').doc(this.state.screen.id).get()
        .then(snapshot => {
            if(snapshot.data().sortOption){
                try {
                    const option = snapshot.data().sortOption
                    this.setState({ sortOpt: option })
                } catch (error) {
                }
            }else{
                this.setState({ sortOpt: 0 })
            }
            this.setState({ sortedCards: null })
            this.setState({ sortedCards: this.sortCards() })
        })
    }

    sortCards(){
        console.log("sorting cards...")
        try {
            const opt = this.state.sortOpt
            console.log(opt, "sort option")
            const cards = this.state.cards.sort((a, b) => (opt===0?(a.name > b.name):opt===1?(a.group > b.group):(a.status > b.status)) ? 1 : -1)
            return cards
        } catch (error){
        }
    }

    render() {

        const loading = this.state.loading;

        const numOfCol = Math.floor(Dimensions.get('window').width / 200)

        const showNetError = this.state.showNetError

        const showCode = this.state.showEnterCode

        const exitError = this.state.exitError

        const sortedCards = this.state.sortedCards

        const codeMode = this.state.codeMode

        const editKeys = this.state.editKeys

        const edited = this.state.edited

        const editKeyError = this.state.editKeyError

        return (
    
          <View style={styles.mainView}>

            <Portal>
                <Dialog style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={showCode} onDismiss={() => this.setState({ showEnterCode: false, exitcode: '', exitError: ''})}>
                
                <ImageBackground
                source={require('../../srcassets/doubledot.png')}
                resizeMode="repeat"
              
                style={{width: '100%', height: '100%'}}
              >

                <Dialog.Content>

                    <View style={{width:'130%', alignSelf: 'center', paddingTop: 15}}>
                        <TextInput
                        label={codeMode?"Code":"Exit-code"}
                        returnKeyType="next"
                        value={this.state.exitcode}
                        onChangeText={(exitcode) => this.setState({ exitcode })}
                        error={!!exitError}
                        errorText={exitError}
                        />
                    </View>

                {codeMode?
                <Button mode="outlined" style={{width: '40%', alignSelf: 'center'}} onPress={() => this.addKeys() }>
                Edit keys
                </Button>
                :<Button mode="outlined" style={{width: '40%', alignSelf: 'center'}} onPress={() => this.exit() }>
                Exit
                </Button>
                }
        
                </Dialog.Content>

                </ImageBackground>
                </Dialog>
            </Portal>

            <Portal>
                <Dialog style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={editKeyError} onDismiss={() => this.setState({ editKeyError: false })}>
                
                <ImageBackground
                source={require('../../srcassets/doubledot.png')}
                resizeMode="repeat"
              
                style={{width: '100%', height: '100%'}}
              >
                <Dialog.Content>
                <Paragraph>Please select a card before linking your key</Paragraph>
                <Button mode="outlined" style={{width: '40%', alignSelf: 'center'}} onPress={() => this.setState({editKeyError: false}) }>
                Noted
                </Button>
                </Dialog.Content>
                </ImageBackground>

                </Dialog>
            </Portal>

            <Portal>
                <Dialog style={{backgroundColor: theme.colors.surface, width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} visible={showNetError} onDismiss={() => this.setState({ showNetError: false })}>
                <Dialog.Content>
                    <Text style={{fontFamily: 'Helvetica Neue', fontSize: 40, fontWeight: 'bold', color: theme.colors.error}}>No internet connection found! Changes may be lost.</Text>
                    <Button mode="contained" style={{width: (isMobile ? '100%' : '40%'), alignSelf: 'center'}} onPress={() => this.setState({ showNetError: false })}>
                        Noted
                    </Button>
                </Dialog.Content>
                </Dialog>
            </Portal>
     
            <View style={{ flex: 1, height: '100%', width: '100%'}}>
                
                {loading
                ?<ActivityIndicator animating={loading} color={theme.colors.loading} />
                :null
                }

                {!loading && !sortedCards
                ? <View style={{justifyContent: 'space-around', paddingHorizontal: 10, flex: 1, alignSelf: 'center'}}>
                    <View style={{alignSelf: 'center', flexDirection: 'row'}}>
                    <BackButton type={3} goBack={this.props.navigation.goBack}/>
                    <Paragraph>No cards yet. Add some...</Paragraph>
                    </View>
                    
                </View>
                :
                
                <View style={{height: '100%', alignSelf: 'center'}}> 
                  <FlatList
                    horizontal={false}
                    scrollEnabled={false}
                    numColumns={numOfCol}
                    data={sortedCards}
                    keyExtractor={item => item.id}
                    renderItem={({ item, index }) => (

                            (item.status
                            ? <View style={[styles.container,
                            { 
                                margin: 4,
                                maxHeight: 300, 
                                width: Math.floor(Dimensions.get('window').width * 0.95 / (numOfCol)),
                                height: Dimensions.get('window').height * 0.8 / (sortedCards.length/(numOfCol)),
                        
                            }]}> 

                                <CardFlip style={styles.cardContainer} ref={ (card) => this['card' + index.toString()] = card } onFlipEnd={() => this.toggleStatus(item)}>

                                <TouchableOpacity 
                                activeOpacity={1}
                                style={[styles.in, (editKeys?(!edited.includes(item.id)?{opacity: 0.7}:null):null)]}
                                onPress={() => this['card' + index.toString()].flip() & this.logClick()}>
                                <View style={{alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 2, marginHorizontal: 2}}>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>{index+1}</Text>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>IN</Text>
                                </View>
                                
                                <View style={{flex: 1, justifyContent: 'center'}}>
 
                                    {editKeys?
                                    (item.key!=null?<View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                        <Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>Key found</Text>
                                        
                                        <TouchableOpacity onPress={() => this.deleteKey(item)}>
                                            <Image
                                            style={{height: 20, width: 20}}
                                            source={require('../../srcassets/trash.png')}
                                            />
                                        </TouchableOpacity>

                                    </View>
                                    :<Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>No key</Text>)
                                    :null
                                    }

                                    <Text numberOfLines={(isMobile ? 1 : 2)} style={{textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 30 : 30)}}>{item.name}</Text>
                                    {item.group?<Text style={{textAlign: 'center', textAlignVertical: 'center'}}>{item.group}</Text>:null}

                                </View>

                                </TouchableOpacity>
                
                                <TouchableOpacity
                                activeOpacity={1}
                                style={[styles.out, (editKeys?(!edited.includes(item.id)?{opacity: 0.7}:null):null)]}
                                onPress={() => this['card' + index.toString()].flip() & this.logClick()}>

                                <View style={{alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 2, marginHorizontal: 2}}>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>{index+1}</Text>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>OUT</Text>
                                </View>
                                
                                <View style={{flex: 1, justifyContent: 'center'}}>

                                {editKeys?
                                    (item.key!=null?<View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                    <Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>Key found</Text>
                                    
                                    <TouchableOpacity onPress={() => this.deleteKey(item)}>
                                        <Image
                                        style={{height: 20, width: 20}}
                                        source={require('../../srcassets/trash.png')}
                                        />
                                    </TouchableOpacity>

                                </View>
                                    :<Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>No key</Text>)
                                    :null
                                    }

                                    <Text numberOfLines={(isMobile ? 1 : 2)} style={{textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 30 : 30)}}>{item.name}</Text>
                                    {item.group?<Text style={{textAlign: 'center', textAlignVertical: 'center'}}>{item.group}</Text>:null}
                                </View>


                                </TouchableOpacity>
                
                                </CardFlip>

                            </View>
                            : <View style={[styles.container, 
                                {
                                    margin: 4,
                                    maxHeight: 300,
                                    width: Math.floor(Dimensions.get('window').width * 0.95 / (numOfCol)),
                                    height: Dimensions.get('window').height * 0.8 / (sortedCards.length/(numOfCol)),
                            
                                }]}>

                                <CardFlip style={styles.cardContainer} ref={ (card) => this['card' + index.toString()] = card } onFlipEnd={() => this.toggleStatus(item)}>
                
                                <TouchableOpacity
                                activeOpacity={1}
                                style={[styles.out, (editKeys?(!edited.includes(item.id)?{opacity: 0.7}:null):null)]}
                                onPress={() => this['card' + index.toString()].flip() & this.logClick()}>
                                <View style={{alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 2, marginHorizontal: 2}}>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>{index+1}</Text>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>OUT</Text>
                                </View>
                                
                                <View style={{flex: 1, justifyContent: 'center'}}>

                                {editKeys?
                                    (item.key!=null?<View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                    <Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>Key found</Text>
                                    
                                    <TouchableOpacity onPress={() => this.deleteKey(item)}>
                                        <Image
                                        style={{height: 20, width: 20}}
                                        source={require('../../srcassets/trash.png')}
                                        />
                                    </TouchableOpacity>

                                </View>
                                    :<Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>No key</Text>)
                                    :null
                                    }

                                    <Text numberOfLines={(isMobile ? 1 : 2)} style={{textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 30 : 30)}}>{item.name}</Text>
                                    {item.group?<Text style={{textAlign: 'center', textAlignVertical: 'center'}}>{item.group}</Text>:null}
                                </View>
                                </TouchableOpacity>
                     
                                <TouchableOpacity
                                activeOpacity={1}
                                style={[styles.in, (editKeys?(!edited.includes(item.id)?{opacity: 0.7}:null):null)]}
                                onPress={() => this['card' + index.toString()].flip() & this.logClick()}>

                                <View style={{alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 2, marginHorizontal: 2}}>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>{index+1}</Text>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: (isMobile ? 15 : 20)}}>IN</Text>
                                </View>
                                
                                <View style={{flex: 1, justifyContent: 'center'}}>

                                {editKeys?
                                    (item.key!=null?<View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                    <Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>Key found</Text>
                                    
                                    <TouchableOpacity onPress={() => this.deleteKey(item)}>
                                        <Image
                                        style={{height: 20, width: 20}}
                                        source={require('../../srcassets/trash.png')}
                                        />
                                    </TouchableOpacity>

                                </View>
                                    :<Text numberOfLines={(isMobile ? 1 : 2)} style={{textDecorationLine: 'underline', textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 20 : 20)}}>No key</Text>)
                                    :null
                                    }

                                    <Text numberOfLines={(isMobile ? 1 : 2)} style={{textAlign: 'center', textAlignVertical: 'center', fontWeight: 'bold', fontSize: (isMobile ? 30 : 30)}}>{item.name}</Text>
                                    {item.group?<Text style={{textAlign: 'center', textAlignVertical: 'center'}}>{item.group}</Text>:null}
                                </View>
                    
                                </TouchableOpacity>
                    
                                </CardFlip>
                    
                            </View>
                            )
                 
                    )}
                  />
                  </View>

                }

            </View>

                <View style={{flexDirection: 'row', width: '100%', alignContent: 'space-around'}}>

                <View style={{flexDirection: 'row', justifyContent: 'center', position: 'absolute', bottom: 5, width: '100%'}}>

                    {sortedCards
                    ?
                    <BackButton type={2} goBack={() => this.askCode(0)}/>
                    :null
                    }

                    <Text style={styles.text}>
                        Flipfire2 - Developed in Switzerland 2022©
                    </Text>

                    {sortedCards
                    ?
                    (!editKeys?
                    <TouchableOpacity style={{alignSelf: 'center', marginLeft: 5}} onPress={() => this.askCode(1)}>
                        <Image
                        style={{height: 20, width: 20}}
                        source={require('../../srcassets/editdark.png')}
                        />
                    </TouchableOpacity>
                    :null)
                    :null
                    }

                </View>

                {sortedCards
                    ?
                    (!editKeys?
                    null
                    :<Button mode="outlined" style={{width: '20%'}} onPress={() => this.setState({editKeys: false}) }>
                    Done linking keys
                    </Button>)
                    :null
                }

                </View>

          </View> 


    
        )
      }
    }
  
export default Flipscreen

const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      },
    in: {
        backgroundColor: theme.colors.error,
        flex: 1, 
        borderRadius: 7,
        shadowRadius: 3,
        shadowColor: 'black',
        shadowOffset: {
          width: 2,
          height: 3,
        },
        shadowOpacity: 0.3,
    },
    out: {
        backgroundColor: theme.colors.success,
        flex: 1, 
        borderRadius: 7,
        shadowRadius: 3,
        shadowColor: 'black',
        shadowOffset: {
          width: 2,
          height: 3,
        },
        shadowOpacity: 0.3,
    },
    row: {
        flexDirection: 'row',
        marginTop: 4,
    },
    cardContainer: {
        width: '100%',
        height: '100%',
      },
      label: {
        lineHeight: 470,
        textAlign: 'center',
        fontSize: 30,
        fontFamily: 'System',
        color: '#ffffff',
        backgroundColor: 'transparent',
      },
      text: {
        color: theme.colors.surface,
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 21,
    },
    mainView: {
        flex: 1,
        height: Dimensions.get('window').height,
        width: Dimensions.get('window').width,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }
  })