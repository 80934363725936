import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { theme } from '../../core/theme'

export default function Paragraph(props, bold) {
  return <Text style={(!bold ? styles.text : styles.text2)} {...props} />
}

const styles = StyleSheet.create({
  text: {
    fontSize: 20,
    lineHeight: 21,
    textAlign: 'center',
    marginBottom: 12,
    fontFamily: 'Helvetica Neue',
    color: theme.colors.primary,
    paddingTop: 30,
  },
  text2: {
    fontSize: 20,
    lineHeight: 21,
    textAlign: 'center',
    marginBottom: 12,
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue',
    color: theme.colors.primary,
    paddingTop: 30,
  },
})
