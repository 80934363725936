import React from 'react'
import { TouchableOpacity, Image, StyleSheet } from 'react-native'
import { getStatusBarHeight } from 'react-native-status-bar-height'


export default function BackButton({ goBack, type}) {
  return (
    <TouchableOpacity onPress={goBack} style={type===undefined ? styles.container : type===1 ? styles.container : type===2 ? styles.container2 : null}>
      <Image
        style={type===undefined ? styles.image : type===1 ? styles.image : type===2 ? styles.image2 : type===3 ? styles.image3 : null}
        source={!type ? require('../../srcassets/arrow_back.png') : require('../../srcassets/arrow_back_dark.png')}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 20 + getStatusBarHeight(),
    left: 20,
    zIndex: 1,
  },
  image: {
    width: 30,
    height: 30,
  },
  container2: {
  },
  image2: {
    width: 20,
    height: 20,
  },
  image3: {
    width: 50,
    height: 50,
  },
})
