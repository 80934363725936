import { isMobile, isMobileOnly } from 'react-device-detect';
import { Platform } from 'react-native';

/*import * as Device from 'expo-device';
Device.getDeviceTypeAsync();
console.log(Device.DeviceType)
console.log(Device.osName)
getDevice();

async function getDevice(){
    const device = await Device.getDeviceTypeAsync()
    console.log(device)
    if(device === 1){
        console.log('true')
        return true
    }
    console.log('false')
    return false
}*/

export const isMobileAll = Platform.select({
    android: true,
    ios: true,
    web: false,
    default: true
}) | isMobile

export const isMobileWeb = isMobile

//export const isOnlyMobile = isMobileOnly | !Platform.isPad

