import React, { Component } from 'react'

import { TouchableOpacity, StyleSheet, View, Dimensions } from 'react-native'
import { Text } from 'react-native-paper'

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase'

export class Reset extends Component {

    constructor(props){     //first to be called whenever a component is created
        super(props);

        this.state = {
            email: '',
    
            emailError: '',

            error: '',

            sent: false
        }

    }

    login(){
        this.props.navigation.navigate("Login");
        return
    }

    restore(){

        firebase.auth().sendPasswordResetEmail(this.state.email)
            .then(() => {
                this.setState({ sent: true})
            })
            .catch((err) => {
                try{
                    if (err.code.includes("email")){
                        this.setState({ emailError: err.message, error: '' })
                    }else{
                        this.setState({ error: err.message, emailError: '' })
                    }
                }catch{
                }
            })
    }

    render() { 

        const emailError = this.state.emailError;

        const error = this.state.error;

        const sent = this.state.sent;

        if(!sent){
        return (
            <Background>
                <BackButton goBack={this.props.navigation.goBack} />
        
                <Header>Reset Password</Header>

                <TextInput
                    label="E-mail address"
                    returnKeyType="done"
                    value={this.state.email}
                    onChangeText={(email) => this.setState({ email })}
                    error={!!emailError}
                    errorText={emailError}
                    autoCapitalize="none"
                    autoCompleteType="email"
                    textContentType="emailAddress"
                    keyboardType="email-address"
                    description="You will receive an email with a password reset link."
                />

                {error
                    ?<Text style={styles.error}>{error}</Text>
                    : null
                }

                <Button
                    mode="contained"
                    onPress={() => this.restore()}
                    style={{ marginTop: 16 }}
                >
                    Reset password
                </Button>

                <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
                    <Text style={styles.text}>
                        Flipfire2 - Made in Sweden 2021©
                    </Text>
                </View>

               </Background>
            )

            }else{
                return(
                    <Background>
  
                        <BackButton goBack={this.props.navigation.goBack} />
                        
                        <Header>Reset Password</Header>
            
                        <Text style={styles.success}>Password reset email sent!</Text>
                        <Button
                            mode="contained"
                            onPress={() => this.login()}
                        >
                            Login
                        </Button>

                        <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
                            <Text style={styles.text}>
                            Flipfire2 - Developed in Switzerland 2022©
                            </Text>
                        </View>

                    </Background>
                )
            }
    }
}

const styles = StyleSheet.create({
    error: {
        fontSize: 13,
        color: theme.colors.error,
        paddingTop: 8,
    },
    success: {
        fontSize: 13,
        color: theme.colors.success,
        paddingTop: 8,
    },
    text: {
        color: theme.colors.text,
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 21,
    },
})

export default Reset
