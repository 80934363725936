
import React, { Component } from 'react'

import { isMobileAll as isMobile } from '../../deviceDetect';

import { StyleSheet, Dimensions, View, Image, Platform, FlatList, Text, TouchableOpacity, ImageBackground, SnapshotViewIOSBase } from 'react-native'

import { Dialog, Portal, ActivityIndicator, Colors, Banner } from 'react-native-paper';

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Button from '../elements/Button'
import Paragraph2 from '../elements/Paragraph2'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'
import firebase from 'firebase'

export class Settings extends Component {

  constructor(props){
    super(props);

    this.state = {
      currentUser: props.route.params.params,
      initName: props.route.params.params.displayName,
      initEmail: props.route.params.params.email,

      name: '',
      email: '',
      password: '',

      error: '',
      showEdit: false,

      loading: false,
      loginError: '',
    }

  }

  resetPass(){
    this.closeAndReset()
    this.props.navigation.navigate("Reset");
    return
  }

  closeAndReset(){
    this.setState({ showEdit: false, name: '', email: '', password: '', error: '', loginError: ''})
  }

  validName(name){
    if(name){
    }else{
        this.setState({ error: "Name can't be empty."})
        return null
    }
    if (name.match(/^[ A-Ö´`0-9'-., ]+$/i) === null) { 
        this.setState({ error: "The name is badly formatted."})
        return null
    }else{
    } 
    return true
  }

  reauthenticate(){
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
        user.email, this.state.password);
    return user.reauthenticateWithCredential(cred);
  }

  edit(type){
    if(type===0){
      const newName = this.state.name
      if(this.validName(newName)){
        firebase.firestore().collection('users').doc(this.state.currentUser.uid)
          .update({
            name: newName,
        })
        firebase.auth().currentUser.updateProfile({
          displayName: newName
        }).then((result) => {
          this.setState({ initName: newName, showEdit: false, error: '', name: '', loading: false})
        }).catch((err) => {
          this.setState({ error: 'Oops... Something went wrong!\n' + err, loading: false })
        })

      }
    }else if(type===1){
        const newEmail = this.state.email
        this.reauthenticate().then(() => {
          var user = firebase.auth().currentUser;
          user.updateEmail(newEmail).then(() => {
            this.setState({ initEmail: newEmail })
            this.closeAndReset()
          }).catch((error) => {
            this.setState({ error: error.message, loading: false, loginError: '' })
          });
          
        }).catch((error) => { 
          this.setState({ loginError: error.message, loading: false, error: '' })
        });
  
      
    }else{
 
        this.reauthenticate().then(() => {
          var user = firebase.auth().currentUser;
          user.updatePassword(this.state.newPassword).then(() => {
            this.closeAndReset()
          }).catch((error) => { 
            this.setState({ error: error.message, loading: false, loginError: '' })
          });
        }).catch((error) => { 
          this.setState({ loginError: error.message, loading: false, error: '' })
        });
      }
    }
  
  render() {

    const initName = this.state.initName
    const initEmail = this.state.initEmail
    const editType = this.state.editType
    const showEdit = this.state.showEdit
    const error = this.state.error
    
    const loading = this.state.loading
    const loginError = this.state.loginError

  return (

    <Background>

      <Header>Settings for {initName}</Header>

      {showEdit
            ? <Portal>
                <Dialog visible={showEdit} style={{backgroundColor: 'white', width: (isMobile ? '90%' : '50%'), alignSelf: 'center'}} onDismiss={() => this.closeAndReset()}>
                    <Dialog.Content>

                        <Paragraph>Edit {editType===0? "name" : (editType===1? "email" : "password")}</Paragraph>

                        <TextInput
                        label={"New " + (editType===0? "name" : (editType===1? "email" : "password")) }
                        returnKeyType="next"
                        value={(editType===0? this.state.name : (editType===1? this.state.email : this.state.newPassword))}
                        onChangeText={(editType===0? (name) => this.setState({name}) : (editType===1? (email) => this.setState({email}) : (newPassword) => this.setState({newPassword})))}
                        error={!!error}
                        errorText={error}
                        secureTextEntry={editType===2?true:false}
                        />

                        {editType!=0?
                        <TextInput
                        label={"Confirm with password"}
                        returnKeyType="next"
                        value={(this.state.password)}
                        onChangeText={(password) => this.setState({password})}
                        error={!!loginError}
                        errorText={loginError}
                        secureTextEntry
                        />
                        :null}

                        {editType!=0?
                        <View style={styles.forgotPassword}>
                          <TouchableOpacity onPress={() => this.resetPass()}>
                              <Text style={styles.forgot}>Forgot your password?</Text>
                          </TouchableOpacity>
                        </View>
                        :null}

                    </Dialog.Content>
                    <Dialog.Actions style={{justifyContent: 'center'}}
                    >
                        {loading
                          ? <Button mode="outlined" style={styles.disabled}> 
                            Loading...
                              <ActivityIndicator animating={loading} color={'#28D158'} /> 
                            </Button> 
                          : <Button style={{width: (isMobile ? '30%' : '40%')}} mode="outlined" onPress={() => this.edit((editType===0? 0 : (editType===1? 1 : 2))) & this.setState({ loading: true })}> 
                            Save
                            </Button>
                          }

                          <Button style={{width: (isMobile ? '30%' : '40%')}} mode="contained" onPress={() => this.closeAndReset()}>Cancel</Button>

                    </Dialog.Actions>
                </Dialog>
            </Portal>
        :null
        }

      <View style={{flex:1, justifyContent: 'space-around'}}>

        <View style={{flex:1, justifyContent: 'space-around', alignItems:'center'}}>
          <Paragraph2>Name</Paragraph2>
          <Text style={styles.itemText}>{initName}</Text>
          <Button
                  mode="contained"
                  onPress={() => this.setState({editType:0,showEdit:true, loading: false})}
                  style={{ marginVertical: (isMobile ? 10 : 30), marginHorizontal: 10, alignSelf: 'center', width: '100%' }}
            >
              Change name
            </Button>
        </View> 

        <View style={{flex:1, justifyContent: 'space-around', alignItems:'center'}}>
          <Paragraph2>Email</Paragraph2>
          <Text style={styles.itemText}>{initEmail}</Text>
          <Button
                  mode="contained"
                  onPress={() => this.setState({editType:1,showEdit:true, loading: false})}
                  style={{ marginVertical: (isMobile ? 10 : 30), marginHorizontal: 10, alignSelf: 'center', width: '100%' }}
            >
              Change email
            </Button>
        </View>

        <View style={{flex:1, justifyContent: 'space-around', alignItems:'center'}}>
          <Paragraph2>Password</Paragraph2>

            <Button
                  mode="contained"
                  onPress={() => this.setState({editType:2,showEdit:true, loading: false})}
                  style={{ marginVertical: (isMobile ? 10 : 30), marginHorizontal: 10, alignSelf: 'center', width: '100%' }}
            >
              Change password
            </Button>


        </View>

      </View>
    
      <Logo/>

      <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
        <Text style={styles.text}>
            Flipfire2 - Developed in Switzerland 2022©
        </Text>
      </View>

    </Background>

  )
  }
}
export default Settings

const styles = StyleSheet.create({
  text: {
      color: theme.colors.text,
      fontFamily: 'Helvetica Neue',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 21,
  },
  itemText: {
    color: 'white',
    fontFamily: 'Helvetica Neue',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: '900',
    lineHeight: 21,
  },
  disabled: {
    opacity: 0.25,
    width: (isMobile ? '30%' : '40%'),
  },
  forgotPassword: {
    width: (isMobile ? '100%' : '40%'),
    alignItems: 'flex-end',
    alignSelf: 'center',
    marginLeft: 50,
    marginBottom: 24,
  },
})
