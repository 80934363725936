
import React, { Component } from 'react'

import { isMobileAll as isMobile } from '../../deviceDetect';

import { StyleSheet, Dimensions, View, Image, Platform, FlatList, Text, TouchableOpacity, ImageBackground } from 'react-native'

import { Dialog, Portal, ActivityIndicator, Colors, Banner } from 'react-native-paper';

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Paragraph from '../elements/Paragraph'
import Button from '../elements/Button'
import Paragraph2 from '../elements/Paragraph2'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'
import firebase from 'firebase'

import { WebView } from 'react-native-webview';
import { ScrollView } from 'react-native-gesture-handler';

import { Linking } from 'react-native'

export class About extends Component {

  constructor(props){
    super(props);

    this.state = {

      videoLink: "https://www.youtube.com/embed/BQvdem08sxk",
      videoLink2: "https://www.youtube.com/embed/4Yehv_sqm0o",
      videoLink3: "https://www.youtube.com/embed/3j7-YCKU4Gg",
    }

  }

  render() {

    const videoLink = this.state.videoLink
    const videoLink2 = this.state.videoLink2
    const videoLink3 = this.state.videoLink3

    function YoutubeEmbed(url) { 
      console.log(url) 
      return(
          <iframe
              width={Dimensions.get('window').width*0.66}
              height={Dimensions.get('window').width*0.33} 
              src={url.url}
              frameBorder="0"
              allowFullScreen
          />
      );
    }

  return (

    <Background>

        <ScrollView style={{ flex: 1, width: Dimensions.get('screen').width}} showsVerticalScrollIndicator={false}>
          <View style={styles.scroll}>

        <Header>About Flipfire2</Header>

        <Text style={styles.customText2}>
            With Flipfire2 teachers and houseparents can see who is inside and outside the building.
        </Text>

        

          <Text style={styles.customText2}>How to use Flipfire2:</Text>

          {isMobile
          ?<WebView
          style={{width:Dimensions.get('window').width*0.9, height:Dimensions.get('window').width*0.5, marginTop: 30}}
          source={{ uri: videoLink }}
          />
          :<YoutubeEmbed url={videoLink}/>
          }

          <Text style={styles.customText2}>Introduction 1</Text>

          {isMobile
          ?<WebView
          style={{width:Dimensions.get('window').width*0.9, height:Dimensions.get('window').width*0.5, marginTop: 30}}
          source={{ uri: videoLink2 }}
          />
          :<YoutubeEmbed url={videoLink2}/>
          }

          <Text style={styles.customText2}>Introduction 2</Text>

          {isMobile
          ?<WebView 
          style={{width:Dimensions.get('window').width*0.9, height:Dimensions.get('window').width*0.5, marginTop: 30}}
          source={{ uri: videoLink3 }}
          />
          :<YoutubeEmbed url={videoLink3}/>
          }

        <View style={{flex:1, flexDirection:(isMobile?'column':'row'), alignContent:'space-around', paddingTop: 50}}>
          <Text style={styles.customText3}> 
            Contact us with any questions at:
          </Text> 
   
          <TouchableOpacity onPress={() => Linking.openURL('mailto:support@flipfire2.com?subject=Question for the Flipfire2 team')}>
            <Text style={[styles.customText3, {textDecorationLine: 'underline'}]}> 
              support@flipfire2.com
            </Text>
          </TouchableOpacity>
        </View>

        <View style={{paddingBottom:40, paddingTop:20}}>
          <Logo/>
        </View>

        <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>

          <Text style={[styles.text, {textDecorationLine: 'underline'}]} onPress={() =>  this.props.navigation.navigate("Privacyscreen")}>
            Privacy Policy
          </Text>

          <Text style={styles.text}>
            Flipfire2 - Developed in Switzerland 2022©
          </Text>
      
        </View>

        </View>
      </ScrollView>

    </Background>
  )
  }
}
export default About

const styles = StyleSheet.create({
  text: {
      color: theme.colors.text,
      fontFamily: 'Helvetica Neue',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 21,
  },
  customText2: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 21,
    textAlign: 'left',
    fontFamily: 'Helvetica Neue',
    color: theme.colors.primary,
    paddingTop: 30,
    paddingBottom: (isMobile?null:30)
},
scroll: {
  flex: 1,
  padding: 20,
  width: '100%',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
},
customText3: {
  fontSize: 20,
  fontWeight: "bold",
  lineHeight: 21,
  textAlign: 'left',
  fontFamily: 'Helvetica Neue',
  color: theme.colors.primary,
  paddingRight: 5,
},
})
