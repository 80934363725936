import React, { Component } from 'react'

import { isMobileAll as isMobile } from '../../deviceDetect';

import { TouchableOpacity, StyleSheet, View, Dimensions, Platform } from 'react-native'

import { Text } from 'react-native-paper'

import Background from '../elements/Background'
import Logo from '../elements/Logo'
import Header from '../elements/Header'
import Button from '../elements/Button'
import BackButton from '../elements/BackButton'
import TextInput from '../elements/TextInput'

import { theme } from '../../core/theme'

import firebase from 'firebase'

export class Login extends Component {

    constructor(props){     //first to be called whenever a component is created
        super(props);
        this.state = {
            email: '',
            password: '',

            emailError: '',
            passError: '',

            error: '',
        }

    }

    signup(){
        this.props.navigation.navigate("Signup");
        return
    }

    resetPass(){
        this.props.navigation.navigate("Reset");
        return
    }

    onLogin(){
        const { email, password, error } = this.state;
        //console.log('klicked på login')
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then((result) => {
            this.props.navigation.popToTop();
            //console.log('done', result)
        })
        .catch((err) => {
            try{
                if (err.code.includes("email")){
                    this.setState({ emailError: err.message, passError: '', error: '' })
                }else if (err.code.includes("password")){
                    this.setState({ passError: err.message , emailError: '', error: '' })
                }else{
                    this.setState({ error: err.message, passError: '', emailError: '' })
                }
            }catch{
            }
        })
}

    render() {

        const emailError = this.state.emailError;

        const passError = this.state.passError;

        const error = this.state.error;

        return (

            <Background>

                <BackButton goBack={this.props.navigation.goBack} />

                <Header>Login</Header>

                <TextInput
                    label="Email"
                    returnKeyType="next"
                    value={this.state.email}
                    onChangeText={(email) => this.setState({ email })}
                    error={!!emailError}
                    errorText={emailError}
                    autoCapitalize="none"
                    autoCompleteType="email"
                    textContentType="emailAddress"
                    keyboardType="email-address"
                />
                <TextInput
                    label="Password"
                    returnKeyType="done"
                    value={this.state.password}
                    onChangeText={(password) => this.setState({ password })}
                    error={!!passError}
                    errorText={passError}
                    secureTextEntry
                />
     
                <View style={styles.forgotPassword}>
                    <TouchableOpacity onPress={() => this.resetPass()}>
                        <Text style={styles.forgot}>Forgot your password?</Text>
                    </TouchableOpacity>
                </View>
        
                {error
                    ?<Text style={styles.error}>{error}</Text>
                    : null
                }
                
                <Button mode="contained" onPress={() => this.onLogin()}>
                    Login
                </Button>

                <View style={styles.row}>
                    <Text>Don't have an account? </Text>
                    <TouchableOpacity onPress={() => this.signup()}>
                    <Text style={styles.link}>Join us</Text>
                    </TouchableOpacity>
                </View>

                <View style={{position: 'absolute', bottom: 5, marginBottom: 8, width: '100%'}}>
                    <Text style={styles.text}>
                    Flipfire2 - Developed in Switzerland 2022©
                    </Text>
                </View>

                </Background>
        )
    }

}

const styles = StyleSheet.create({
forgotPassword: {
    width: (isMobile ? '100%' : '40%'),
    alignItems: 'flex-end',
    marginBottom: 24,
    },
    row: {
    flexDirection: 'row',
    marginTop: 4,
    },
    forgot: {
    fontSize: 13,
    color: theme.colors.text,
    },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
  text: {
    color: theme.colors.text,
    fontFamily: 'Helvetica Neue',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 21,
  },
  text: {
    color: theme.colors.text,
    fontFamily: 'Helvetica Neue',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 21,
  },
})

export default Login
